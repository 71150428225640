@import 'fonts.scss';
@import 'style.scss';



html,body{
	font-size: 14px;
	line-height: 22px;
	overflow-x: hidden;
}
html{
	
}
body{
	overflow: hidden;
	position: relative;
	background-color: #0B0B0B;
	font-family: 'TT Interfaces';
}
input{
	border: none;
	box-shadow: none;
	outline: none;
	font-family: 'TT Interfaces';
	&::placeholder{
		font-family: 'TT Interfaces';
	}
}

ul,li{
	list-style-type: none;
	margin-bottom: 0;
}
p{
	margin-bottom: 0;
}
a{
	color: inherit;
	text-decoration: none;
	&:hover{
		text-decoration: none;
		color: inherit;
	}
}
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	outline: none!important;
}
img{
	max-width: 100%;
}
@import '_media.scss';