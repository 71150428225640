.page-top{
	border-radius: 0px 0px 20px 20px;
	background: #121212;
	height: 40px;
	width: 100%;
	margin-bottom: 10px;
}
.container{
	max-width: 1160px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.page-wrapper{
	position: relative;
	.container{
		position: relative;
		z-index: 1;
	}

}
.section-bg{
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	max-width: 100%;
	width: 100%;
	img{
		width: 100%;
	}
}
.row{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}
.page-left{
	width: 100%;
	max-width: 260px;
}
.page-right{
	width: 100%;
	max-width: 890px;
}
.nav{
	border-radius: 20px;
	background: #121212;
	width: 100%;
	min-height: 400px;
	padding-left: 32px;
	padding-top: 26px;
	padding-bottom: 25px;
	padding-right: 35px;
	margin-bottom: 10px;
}
.logo-text{
	display: block;
	color: #8D8D8D;
	font-size: 13px;
	line-height: 22px;
}
.menu{
	margin-top: 43px;
	
	ul{
		margin-top: 24px;
		margin-bottom: 36px;
		li{
			margin-bottom: 15px;
			font-size: 15px;
			font-weight: 400;
			line-height: 15px;
			text-transform: capitalize;
			&:last-child{
				margin-bottom: 0;
			}
			a{
				display: flex;
				color: #797979;
				transition: .4s all ease;
				align-items: center;
				&:hover{
					.icon{
						background: #6FFF8B;
						path{
							fill: #000;
						}
					}
					color: #fff;
				}
			}
			.icon{
				width: 32px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				background: #393939;
				margin-right: 16px;
				transition: .4s all ease;
				path{
					fill: #666666;
					transition: .4s all ease;
				}
			}
		}
		li.active{
			
			
			a{
				font-weight: 600;
				color: #FFF;
			}
			.icon{
				background: #6FFF8B;
				path{
					fill: #000;
				}
			}
		}
	}
}
.menu__title{
	color: rgba(255, 255, 255, 0.33);
	font-weight: 600;
	line-height: 14px;
	letter-spacing: 1.4px;
	text-transform: uppercase;
}
.nav-btn{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 7px;
	padding-bottom: 7px;
	padding-right: 6.3px;
	padding-left: 13px;
	border-radius: 3.861px;
	background: #797979;
	color: #000;
	font-size: 15px;
	line-height: 15px;
	text-transform: capitalize;
	margin-bottom: 8px;
	.arrow{
		width: 25px;
		height: 25px;
		border-radius: 4px;
		background: #1B1B1B;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&:last-child{
		margin-bottom: 0;
	}
}
.nav-btn--dark{
	background: transparent;
	color: #797979;
	border: 1px solid #797979;
	.arrow{
		background: #797979;
		path{
			fill: #000000;
		}
	}
}
.booking-places{
	width: 100%;
	padding-left: 25px;
	padding-top: 15px;
	padding-bottom: 25px;
	padding-right: 60px;
}
.booking-places__title{
	color: #FFF;
	font-size: 24px;
	font-weight: 600;
	line-height: 34px;
	margin-bottom: 3px;
}

.select-club{
	display: flex;
	align-items: center;
	img{
		margin-right: 4px;
	}
	color: #9C9C9C;
	font-size: 14px;
	line-height: 100%;
}
.booking-select{
	line-height: 34px;
}
.open-select{
	color: #6FFF8B;
	position: relative;
	margin-left: 5px;
	padding-right: 20px;
	cursor: pointer;
	&::after{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 4px;
		width: 9.479px;
		height: 8.75px;
		border-radius: 2px;
		background: #6FFF8B;
		content: '';
		display: block;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 60%;
		background-image: url('../img/icons/booking-check.svg');
	}
}
.btn{
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-position: center;

	color: #000;
	font-size: 15px;
	line-height: 15px;
	text-transform: capitalize;
}
.photo-bg{
	background-size: cover;
	background-position: center;
}
.booking-places{
	height: 161px;
	margin-bottom: 10px;
	background-image: url('../img/bg-blocks/booking-place-bg.svg');
}
.booking-btn{
	width: 173.51px;
	height: 49.254px;
}
.booking-btn{
	margin-top: 8px;
	background-image: url('../img/bg-blocks/booking-btn.svg');
}

.page-right{
	border-radius: 20px;
	background: #121212;
	padding: 20px;
}
.select-country{
	border-radius: 8px;
	display: inline-flex;
	border: 1px solid #313131;
	padding-right: 9.48px;
	padding-left: 8.75px;
	height: 25.5px;
	align-items: center;

	font-size: 10.208px;
	img{
		margin-right: 6px;
		position: relative;
		top: 0px;
	}
}
.flex-a{
	display: flex;
	align-items: center;
}
.language-select{
	display: flex;
	align-items: center;
	position: relative;
	top: 2px;
	color: #E4E4E4;
	font-size: 10.152px;
	font-weight: 400;
	line-height: 100%;
	span{
		position: relative;
		top: -2px;
		opacity: .5;
	}
	.language-selected{
		margin-left: 6px;
		position: relative;
		padding-right: 9px;

		&::after{
			position: absolute;
			top: 5px;
			width: 5px;
			height: 3px;
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/language-arrow.svg');
			display: block;
			content: '';
			right: 0px;
		}
		img{
			max-width: 14.5px;
		}
	}
}
.info-soc{
	margin-left: 29px;
	margin-right: 11px;
}
.info-soc__link{
	display: flex;
	margin-right: 4.5px;
	max-width: 25px;
	&:last-child{
		margin-right: 0;
	}
	svg{
		*{
			transition: .4s all ease;
		}
		rect{
			opacity: 0.05;
			fill: #fff;
		}
		path{
			fill: #666666;
		}
	}
	&:hover{
		svg{
			rect{
				opacity: 1;
				fill: #338FEC;
			}
			path{
				fill: #fff;
			}
		}
	}
}
.search{
	input{
		border-radius: 4.5px;
		background: #272727;
		width: 108.766px;
		height: 25px;
		padding-left: 10.5px;
		color: #fff;
		font-size: 10.152px;
	}
}
.top-info{
	padding-bottom: 16px;
	border-bottom: 1px solid #333333;
	margin-bottom: 15px;
}
.section-title{
	color: rgba(255, 255, 255, 0.33);
	font-family: TT Interfaces;
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 10px;
}
.main-top{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 22px;
}

.fresh-block{
	padding-left: 11px;
	padding-top: 11px;
	padding-bottom: 11px;
	width: 100%;
	height: 241px;
	background-image: url('../img/fresh/fresh-block.svg');
	display: flex;
	align-items: center;
}
.fresh-block-photo{
	margin-right: 24px;
	max-width: 304px;
	width: 100%;
	height: 218px;
	border-radius: 11px;
}
.fresh-row{
	margin-top: 16px;
}
.fresh-block__title{
	color: #FFF;
	font-size: 20px;
	font-weight: 600;
	line-height: 130%;
	margin-bottom: 14px;
	max-width: 335px;
}
.fresh-block__text{
	max-width: 310px;
	font-weight: 400;
	line-height: 22px;
	color: rgba(255, 255, 255, 0.40);
	margin-bottom: 36px;
}
.fresh-block__more{
	color: #00FF60;
	font-size: 16px;
	padding-right: 32px;
	font-weight: 600;
	line-height: 26px;
	position: relative;
	&::after{
		top: 50%;
		transform: translateY(-50%);
		right: 0px;
		background-size: cover;
		background-position: center;
		content: '';
		display: block;
		width: 24px;
		height: 7px;
		background-image: url('../img/icons/fresh-arrow.svg');
		position: absolute;
	}
}
.fresh-block{
	margin-bottom: 20px;
	&:last-child{
		margin-bottom: 0;
	}
	position: relative;
}
.fresh-block__time{
	position: absolute;
	top: -5px;
	right: 0px;
	color: #5F5F5F;
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 24px;
	img{
		margin-right: 5px;
	}
}
section{
	margin-bottom: 60px;
}

.map-wrapper{
	width: 100%;
	min-height: 365px;
}

.main-block{
	margin-bottom: 10px;
}
.main-row{
	margin-bottom: -10px;
}
.main-block--left{
	justify-content: space-between;
	
	.main-block__title{
		align-self: flex-start;
		text-align: right;
	}
	.main-block__more{
		align-self: flex-end;
	}
}
.map{
	margin-bottom: 0;
}
.page-wrapper{
	padding-bottom: 20px;
}
.news-slide__photo{
	width: 215px;
	height: 177px;
	margin-bottom: 13px;
}
.news-slide{
	padding-left: 7.15px;
	padding-top: 7.15px;
	padding-right: 9.31px;
	width: 232px;
	height: 318px;
	margin-right: 15px;
	position: relative;
	.content{
		padding-left: 38.25px;
	}
}
.news-slide__title{
	color: #FFF;
	font-size: 20px;
	font-weight: 600;
	line-height: 110%;
	margin-bottom: 3px;
}
.news-slide__text{
	line-height: 17px;
	color: rgba(255, 255, 255, 0.40);
	
}
.news-slide__more{
	line-height: 26px;
	color: #888;
	padding-right: 31px;
	position: relative;
	&::after{
		position: absolute;
		top: 57%;
		transform: translateY(-50%);
		content: '';
		display: block;
		width: 24px;
		height: 7px;
		background-size: cover;
		background-position: center;
		background-image: url('../img/icons/news-more.svg');
		right: 0px;
	}
}
.news-slide{
	background-image: url('../img/bg-blocks/news-slide.svg');
	.height-wrapper{
		height: 64px;
		margin-bottom: 22px;
	}
}
.news-slide__date{
	color: rgba(255, 255, 255, 0.33);
	transform: rotate(-90deg);
	position: absolute;
	left: -35px;
	bottom: 50px;
	font-size: 12px;
	font-weight: 600;
	line-height: 14px;
}
.news-slider{
	overflow-x: scroll;
	padding-bottom: 21px;
	margin-right: -21px;
	&::-webkit-scrollbar {
	  height: 6px;
	  cursor: pointer;
	}

	&::-webkit-scrollbar-track {
	  border-radius: 10px;
	  background: rgba(255, 255, 255, 0.04);
	  margin-right: 20px;
	}

	&::-webkit-scrollbar-thumb {
	  border-radius: 10px;

	  background: #6FFF8B;
	}
}
.cybersport{
	margin-right: -20px;
	.cybersport-slider{
		padding-bottom: 20px;
		&::-webkit-scrollbar {
		  height: 6px;
		  cursor: pointer;
		}

		&::-webkit-scrollbar-track {
		  border-radius: 10px;
		  background: rgba(255, 255, 255, 0.04);
		  margin-right: 20px;
		  cursor: pointer;
		}

		&::-webkit-scrollbar-thumb {
		  border-radius: 10px;

		  background: #6FFF8B;
		}
	}
}
.page-right{

}
.news{
	padding-bottom: 33px;
	border-bottom: 1px solid #333333;
	position: relative;
}
.news-slider::-webkit-scrollbar {
  
}
 
/* Скрываем scrollbar для IE, Edge и Firefox */

.arena-block{
	position: relative;
	.content{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		
		justify-content: flex-end;
		padding-left: 30px;
		padding-bottom: 20px;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
.soon-block{
	border-radius: 8px;
	background: #3D3D3D;
	width: 100%;
	max-width: 162px;
	padding-left: 10px;
	padding-top: 7px;
	padding-bottom: 7px;
	margin-bottom: 10px;
}
.soon-block__pretitle{
	color: #787878;
	font-size: 7px;
	line-height: 7px;
	letter-spacing: 0.7px;
	text-transform: uppercase;
	margin-bottom: 2px;
}
.soon-block__title{
	font-size: 14px;
	color: #787878;
	margin-bottom: 3px;
	font-weight: 600;
	line-height: 16px;
	min-height: 32px;
	letter-spacing: -0.28px;
	text-transform: uppercase;
	max-width: 119px;
}
.soon-block-bottom{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
}
.soon-block__count{
	color: #6FFF8B;
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
}
.soon{
	.row{
		margin-bottom: -10px;
	}
}
.nav-reservation{
	margin-top: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 15px;
	font-weight: 400;
	line-height: 15px;
	text-transform: capitalize;
	border-radius: 3.861px;
	background: #6FFF8B;
	padding-left: 15px;
	padding-top: 7.1px;
	padding-bottom: 7.1px;
	font-family: TT Interfaces;
	padding-right: 6.3px;
	.arrow{
		width: 24.713px;
		height: 24.713px;
		border-radius: 3.861px;
		background: #1B1B1B;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 15px;
	}
}
.main-block--big{
	width: 100%;
	padding-left: 35px;
	padding-top: 32px;
	min-height: 400px;
	.main-block__title{
		color: #6FFF8B;
		font-family: TT Interfaces;
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: 88.5%;
		margin-bottom: 11.28px;
		text-transform: uppercase;
	}
	.main-block__text{
		color: #FFF;
		font-family: TT Interfaces;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
		max-width: 310px;
	}
}

.main-block{
	border-radius: 13px;
}
.main-block-slider{
	width: 100%;
	max-width: 555px;
}
.main-wrapper{
	max-width: 285px;
	width: 100%;
	.main-block{
		padding-left: 15px;
		color: #FFF;
		padding-top: 16px;
		min-height: 195px;
		padding-bottom: 9px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}
	.main-block__title{
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 110%;
		margin-bottom: 9px;
	}
	.main-block__text{
		color: #FFF;
		max-width: 247px;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
	}
}

.main-block__more{
	color: var(--CBR-GREEN, #00FF60);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
	padding-right: 32px;
	position: relative;
	&::after{
		top: 50%;
		transform: translateY(-50%);
		right: 0px;
		background-size: cover;
		background-position: center;
		content: '';
		display: block;
		width: 24px;
		height: 7px;
		background-image: url('../img/icons/fresh-arrow.svg');
		position: absolute;
	}
}
.main-block.main-block--application{
	padding-top: 10px;
	padding-bottom: 14px;
	.main-block__title{
		color: #000;
	}
}
.main-block__download{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 3.861px;
	background: #FFF;
	padding-left: 13px;
	padding-right: 7.3px;
	padding-top: 7.1px;
	padding-bottom: 7.1px;
	color: #000;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	text-transform: capitalize;
	.arrow{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24.713px;
		height: 24.713px;
		flex-shrink: 0;
		border-radius: 3.861px;
		background: #000;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 15px;
	}
}
.direction-block{
	max-width: 161px;
	min-height: 284px;
	width: 100%;
	border-radius: 12px;
	flex-shrink: 0;
	padding-left: 18px;
	padding-top: 18px;
	position: relative;
}
.direction-block-border{
	position: absolute;
	left: 4px;
	top: 5px;
}
.direction-block__title{
	color: #FFF;
	
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 108%;
	text-align: left;
}
.fresh{
	margin-bottom: 70px;
}

.main-arenas{
	.main-block__title{
		color: #FFF;
		font-family: TT Interfaces;
		font-size: 21px;
		font-style: normal;
		font-weight: 600;
		line-height: 26px; /* 123.81% */
		letter-spacing: -0.42px;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	.section-title{
		margin-bottom: 0;
		position: relative;
		top: 4px;
	}
}
.section-title--big{
	color: rgba(255, 255, 255, 0.33);
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.main-top{
	display: flex;
	align-items: flex-end;
}
.main-arenas{
	padding-top: 20px;
}
.type{
	.col{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 334px;
	}
	.soon-block{
		background: rgba(217, 217, 217, 0.10);
	}
	.soon-block.active{
		
		background: #6FFF8B;
		.soon-block__title{
			color: #000;
			font-weight: 700;
		}
		.soon-block__pretitle{
			color: #000;
		}
		.soon-block__count{
			color: #000;
		}
	}
}

.soon-block-bottom--right{
	justify-content: flex-end;
}
.type-block{
	width: 100%;
	max-width: 490px;
	border-radius: 10px;
	background: rgba(217, 217, 217, 0.10);
	padding-left: 24px;
	padding-top: 27px;
	padding-bottom: 16px;
	padding-right: 22px;
}
.type-block__title{
	color: #FFF;
	font-size: 25px;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: -0.5px;
	text-transform: uppercase;
	max-width: 193px;
	margin-top: 6px;
	margin-bottom: 46px;
}
.type-block__where{
	color: #6FFF8B;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	display: inline-flex;
	align-items: center;
	margin-left: 7px;
	svg{
		margin-right: 10px;
	}
	span{
		display: inline-block;
		line-height: 100%;
		border-bottom: 1px solid #6FFF8B;
	}
}
.type-navslider{
	margin-top: 11px;
	padding-bottom: 13px;
	border-bottom: 1px solid #454545;
}
.type-navslider__slide{
	border-radius: 10px;
	background: rgba(217, 217, 217, 0.10);
	max-width: 103px;
	width: 100%;
	min-height: 92px;
	flex-shrink: 0;
}
.type-block-video{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 217px;
	border-radius: 10px;
	background: rgba(217, 217, 217, 0.10);
	min-height: 154px;
}
.play-video{
	background-size: cover;
	background-position: center;
	width: 50px;
	height: 50px;
	cursor: pointer;
	border-radius: 50%;
	background-image: url('../img/icons/play-video.svg');
}
.type-characteristics{
	margin-top: 19px;
	padding-left: 5px;
}
.type-characteristics__title{
	max-width: 166px;
	margin-bottom: 16px;
	color: #FFF;
	font-size: 20px;
	font-weight: 600;
	line-height: 130%;
}
.type-characteristics{
	.wrapper{
		max-width: 217px;
		width: 100%;
	}
}
.type-characteristics__item{
	border-radius: 2.575px;
	color: #fff;
	background: rgba(255, 255, 255, 0.20);
	display: flex;
	align-items: center;
	padding-left: 4px;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-right: 5px;
	margin-bottom: 7px;
	margin-right: 5px;
	svg{
		margin-right: 5.5px;
	}
	strong{
		margin-left: 2px;

		color: #fff;
		border-radius: 2.575px;
		background: #000;
		font-size: 7.01px;
		font-weight: 500;
		line-height: 100%;
		padding: 3px;
		letter-spacing: -0.14px;
	}
	span{
		
		font-size: 12.015px;
		font-weight: 500;
		line-height: 14.589px;
		letter-spacing: -0.24px;
	}
}
.type-characteristics-items{
	margin-bottom: -7px;
	display: flex;
	flex-wrap: wrap;
}
.type-characteristics__item.active{
	background: #6FFF8B;
	color: #000;
}
.type-btns{
	margin-top: 17px;
}
.type-power{
	border-radius: 10px;
	background: rgba(217, 217, 217, 0.10);
	padding-left: 15px;
	padding-top: 9px;
	padding-bottom: 16px;
}
.list-circle{
	color: rgba(255, 255, 255, 0.40);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 17px;
	li{
		margin-bottom: 8px;
		padding-left: 13px;
		position: relative;
		&::before{
			position: absolute;
			top: 6px;
			content: '';
			display: block;
			width: 5px;
			height: 5px;
			background-size: cover;
			background: #ED43E5;
			left: 0px;
			border-radius: 50%;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.type-btn{
	display: inline-flex;
	position: relative;
	span{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		text-align: center;
		color: #000;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: 15px;
	}
}
.type-btn--dark{
	span{
		color: #fff;
	}
}
.cities-block__title{
	color: #FFF;
	font-size: 21px;
	font-weight: 600;
	line-height: 26px;
	letter-spacing: -0.42px;
	text-transform: uppercase;
	margin-bottom: 5px;
}
.cities-block{
	position: relative;
	display: inline-block;

	.content{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 20px;
		padding-left: 30px;
		align-items: flex-start;
	}
}
.cities-block__more{
	color: #6FFF8B;
	font-size: 14px;
	font-weight: 600;
	line-height: 26px;
	position: relative;
	padding-right: 32px;
	&::after{
		top: 50%;
		transform: translateY(-50%);
		right: 0px;
		background-size: cover;
		background-position: center;
		content: '';
		display: block;
		width: 24px;
		height: 7px;
		background-image: url('../img/icons/fresh-arrow.svg');
		position: absolute;
	}
}
.cities-block{
	margin-bottom: 14px;
}
.cities{
	.row{
		margin-bottom: -14px;
	}
}
.cities-block--disable{

	.cities-block__title{
		color: #767676;
	}
	.cities-block__opening{
		color: #4B4B4B;
		font-size: 14px;
		font-weight: 600;
		line-height: 26px;
		margin-top: -5px;
	}
}
.main-top--standart{
	.section-title{
		margin-bottom: 0;
		position: relative;
		top: 5px;
	}
}

.news-other{
	padding-bottom: 0;
	border-bottom: none;
	.news-slide{
		min-width: 230px;
		height: 318px;
		background-size: cover;
		background-position: center;
		padding-top: 20px;
		padding-left: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
	.news-slide__title{
		color: #FFF;
		font-size: 20px;
		font-weight: 600;
		line-height: 130%;
	}
	.news-slide__more{
		color: #6FFF8B;
		font-size: 14px;
		font-weight: 600;
		margin-left: 25px;
		&::after{
			background-image: url('../img/icons/more-arrow.svg');
		}
	}
	.news-slider{
		margin-bottom: 17px;
	}
}
.news-top{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.news-link{
	color: rgba(255, 255, 255, 0.33);
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-right: 20px;
	position: relative;
	bottom: 5px;
}
.news-btn{
	span{
		font-size: 15px;
	}
	img{
		width: 255px;
	}
}
.page-bread{
	color: rgba(255, 255, 255, 0.33);
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 18px;
	a{
		margin-right: 23px;
		position: relative;
		&::after{
			position: absolute;
			top: 5px;
			content: '';
			display: block;
			width: 6px;
			height: 7px;
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/bread-arrow.svg');
			right: -14px;
		}
	}
}
.single-block{
	position: relative;
	color: #FFF;
	.content{
		position: absolute;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		padding-left: 35px;
		padding-top: 29px;
		padding-right: 27px;
	}
	.type-characteristics-items{
		margin-bottom: 17px;
	}
}
.single-block__title{
	color: #FFF;
	max-width: 461px;
	font-size: 60.658px;
	font-weight: 700;
	line-height: 55.67px;
	letter-spacing: -1.82px;
	text-transform: uppercase;
}
.single-block{
	.line{
		margin-bottom: -40px;
	}
}
.single-play{
	width: 107px;
	height: 107px;
	border: 1px solid #6FFF8B;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}
.single-google__count{
	color: #000;
	font-size: 45.985px;
	font-weight: 600;
	line-height: 57px;
}
.single-google__text{
	color: #1A73E8;
	font-size: 15.328px;
	font-weight: 600;
}
.single-google{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.single-google{
	background-size: cover;
	background-position: center;
	width: 130px;
	height: 175px;
	background-image: url('../img/bg-blocks/google-bg.svg');
	padding-top: 20px;
}
.single-google__stars{
	margin-top: 7px;
	margin-bottom: 5px;
}
.single-google__text{
	margin-bottom: 6px;
}
.btn-figure{
	position: relative;
	color: #000;
	display: inline-flex;
	span{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: max-content;
		font-size: 15px;
		font-weight: 400;
		line-height: 15px;
	}
}
.single-btn{
	span{
		font-weight: 600;
	}
}
.single-gallery__item{
	display: inline-flex;
}
.single-gallery{
	margin-top: 8px;
}
.block-figure{
	position: relative;
	.content{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.zones{
	.row{
		margin-bottom: -17px;
	}
}
.zones-block{
	margin-bottom: 17px;
	.content{
		padding: 11px;
		padding-bottom: 0;

	}
	.wrapper{
		padding-left: 12px;
	}
}
.zones-block__title{
	color: #FFF;
	font-size: 20px;
	margin-bottom: 5px;
	font-weight: 700;
	line-height: 130%;
}
.zones-block__text{
	color: rgba(255, 255, 255, 0.60);
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.32px;
	max-width: 370px;
	margin-bottom: 17px;
	min-height: 66px;
}
.zones-block__img{
	margin-bottom: 11px;
}
.news-single{
	padding-bottom: 0;
	border-bottom: none;
}
.price-title{
	color: rgba(255, 255, 255, 0.33);
	font-size: 20px;
	font-weight: 300;
	margin-bottom: 25px;
	strong{
		font-weight: 600;
	}
}
.price-block{
	color: #FFF;
	.content{
		padding-left: 10px;
		padding-right: 13px;
		padding-top: 20px;
	}
	.top{
		padding-left: 18px;
	}
	.bottom{
		text-align: center;
	}
}
.price-block__pretitle{
	color: #6D6D6D;
	font-size: 15px;
	font-weight: 300;
	padding-left: 2px;
}
.price-block__title{
	color: #6FFF8B;
	font-size: 40px;
	font-weight: 600;
	line-height: 50px;
	margin-top: -5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #5C5C5C;
	margin-bottom: 15px;
}
.price-characteristics__item{
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	color: #FFF;
	font-size: 15px;
	font-weight: 300;
	line-height: 100%;
	&:last-child{
		margin-bottom: 0;
	}
	.icon{
		width: 17px;
		height: 17px;
		border-radius: 3px;
		background: #FFF;
		margin-right: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.price-components__item{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 12px;
	font-weight: 300;
	font-size: 12px;
	line-height: 14.5px;
	strong{
		font-weight: 700;
	}
}
.price-table{
	border-radius: 10px;
	background: rgba(217, 217, 217, 0.10);
	padding-left: 16px;
	padding-top: 12px;
	padding-bottom: 26px;
	padding-right: 14px;
	margin-top: 21px;
	text-align: left;
	margin-bottom: 10px;
}
.price-table__title{
	color: #6FFF8B;
	margin-bottom: 14px;
	font-size: 22.359px;
	font-weight: 600;
}
.price-table-item{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 13.27px;
	line-height: 18.957px;
	padding-bottom: 6.5px;
	margin-bottom: 7.5px;
	border-bottom: 1px solid #555555;
	&:last-child{
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: none;
	}
	strong{
		width: 45%;
		font-weight: 600;
	}
	span{
		width: 27.5%;
	}
}
.price-block-black{
	color: #000;
	.price-block__title{
		color: #000;
	}
	.price-block__pretitle{
		color: #000;
	}
	.price-characteristics__item{
		color: #000;
		.icon{
			background: #000;
		}
	}
	.price-table{
		background: rgba(0, 0, 0, 0.10);
	}
	.price-table__title{
		color: #000;
	}
	.price-btn{
		color: #fff;
	}
}
.night-block__title{
	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 72.967px;
	font-style: normal;
	font-weight: 700;
	line-height: 88.5%;
	text-transform: uppercase;
	margin-bottom: 7px;
}
.night-block{
	.content{
		padding-left: 37px;
		padding-top: 36px;
		padding-bottom: 31px;
		padding-right: 27px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}
.night-block__subtext{
	width: 228px;
	height: 60px;
	color: #000;
	font-size: 18.353px;
	font-style: normal;
	font-weight: 700;
	line-height: 117%;
	letter-spacing: -0.551px;
	text-transform: uppercase;
	margin-bottom: 60px;
	margin-top: 7px;
	padding-top: 8px;
	padding-left: 8px;
	background-image: url('../img/bg-blocks/night-text.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
}
.night-btn{
	align-self: flex-end;
}
.comfort-block__title{
	margin-bottom: 15px;
}
.comfort-block-img{
	margin-bottom: 20px;
}
.comfort-block__title{
	color: #FFF;
	font-size: 20px;
	font-weight: 600;
	line-height: 130%;
}
.comfort-block__subtext{
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	max-width: 305px;
	line-height: 22px;
}
.comfort-block{
	max-width: 410px;
	margin-bottom: 50px;
	.content{
		padding-left: 30px;
	}
	ul{
		margin-top: 10px;
		
		li{
			padding-left: 14px;
			margin-bottom: 10px;
			max-width: 288px;
			font-size: 14px;
			line-height: 17px; 
			color: rgba(255, 255, 255, 0.40);
			position: relative;
			&::after{
				position: absolute;
				top: 9px;
				content: '';
				display: block;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #ED43E5;
				left: 0px;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.list-big{
		li{
			font-size: 16px;
			line-height: 22px;
			color: #FFF;
			max-width: 320px;
		}
	}
}
.comfort{
	.row{
		margin-bottom: -50px;
	}
}
.comfort-internet{
	border-radius: 10px;
	background: #222333;
	padding: 9px 8px;
	display: inline-flex;
	margin-top: 20px;
}
.food{
	color: #fff;
	.content{
		padding-left: 70px;
		padding-top: 50px;
	}
}
.food__title{
	color: #FFF;
	font-size: 20px;
	font-weight: 600;
	max-width: 278px;
	margin-bottom: 20px;
}
.food-item{
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	&:last-child{
		margin-bottom: 0;
	}
	img{
		margin-right: 15px;
	}
	p{
		color: #FFF;
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		max-width: 263px;
	}
	.icon{
		display: flex;
	}
}
.food-list{
	margin-bottom: 36px;
}
.food-block__img{
	position: absolute;
	bottom: 6px;
	right: 0px;
}
.games-block{
	.content{
		padding-left: 60px;
		padding-top: 25px;
		padding-right: 60px;
	}
}
.games-block-nav__btn{
	padding: 7px 12px;
	border-radius: 3px;
	background: #292929;
	transition: .4s all ease;
	color: #545454;
	margin-right: 10px;
	&:last-child{
		margin-right: 0;
	}
	font-family: TT Interfaces;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px;
	text-transform: uppercase;
	&:hover{
		background: #6FFF8B;
		color: #000;
	}
}
.games-block-nav__btn.active{
	background: #6FFF8B;
	color: #000;
}
.games-slider{
	margin-top: 37px;
}
.games-slide-item{
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	width: 25%;
	.photo-bg{
		border-radius: 50%;
		width: 60px;
		height: 60px;
		margin-right: 15px;
	}
	.text{
		color: #FFF;
		font-family: TT Interfaces;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		text-transform: uppercase;
		max-width: 76px;
	}
}
.games-slide{
	display: flex!important;
	flex-wrap: wrap;
}
.games{
	.center{
		text-align: center;
		margin-top: 25px;
	}
}
.games-block__logo{
	position: absolute;
	top: 8px;
	right: 122px;
}
.single-gallery__item{
	max-width: 134px;
}
.first-screen__block{
	min-height: 428px;
	border-radius: 23px;
	padding-left: 70px;
	padding-top: 135px;
}
.first-screen__block__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 45px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%;
	letter-spacing: -1.8px;
	text-transform: uppercase;
	max-width: 489px;
}
.first-screen__block__text{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 13.902px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 16.682px */
	letter-spacing: -0.139px;
	max-width: 360px;
}
.first-screen-btn{
	color: #000;
	font-family: TT Interfaces;
	font-size: 13.902px;
	font-style: normal;
	font-weight: 600;
	line-height: 100%;
	padding-left: 14px;
	padding-right: 15px;
	letter-spacing: -0.417px;
	text-transform: uppercase;
	margin-right: 6.25px;
	transition: .4s all ease;
	display: inline-flex;
	align-items: center;
	height: 42px;
	border-radius: 3.475px;
	background: #6FFF8B;
	span{
		margin-right: 6.5px;
	}
}
.first-screen-btns{
	margin-top: 35px;
}
.first-screen-btn.btn-transparent{
	background: transparent;
	border: 0.695px solid #6FFF8B;
	color: #6FFF8B;
}
.slick-dots{
	bottom: 17.5px;
}
.slick-dots li{
	width: 6.951px;
	height: 6.951px;
	border-radius: 6.951px;
	border: 0.695px solid #585858;
	transition: .4s all ease;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	button{
		transition: .4s all ease;
		padding: 0px;
		width: 4.171px;
		height: 4.171px;
		border-radius: 50%;
		background: transparent;
	}
}
.slick-dots .slick-active{
	button{
		background: #6FFF8B;
	}
}
.tidings__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%; /* 40.075px */
	letter-spacing: -1.4px;
	text-transform: uppercase;
	margin-bottom: 10px;
}
.tidings-category{
	color: #666;
	font-family: TT Interfaces;
	font-size: 10.483px;
	font-style: normal;
	font-weight: 400;
	line-height: 108%;
	padding: 8px 20px;
	border-radius: 4.368px;
	margin-right: 7px;
	border-radius: 3.801px;
	transition: .4s all ease;
	border: 0.437px solid #4D4D4D;
}
.tidings-category.active{
	background: #6FFF8B;
	color: #000;
}
.tidings__all{
	display: inline-flex;
	align-items: center;

	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 12.231px;
	font-style: normal;
	font-weight: 600;
	line-height: 22.714px;
	span{
		margin-right: 14.15px;
	}
}
.tidings-categories{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tidings-row{
	margin-top: 19px;
}
.tidings-block-date{
	color: #666;
	font-family: TT Interfaces;
	font-size: 8.736px;
	font-style: normal;
	font-weight: 400;
	line-height: 108%;
	padding: 4px 5px;
	background: rgba(255, 255, 255, 0.11);
	display: inline-block;
	border-radius: 3.801px;
}
.tidings-block{
	width: 100%;
	max-width: 270px;
	min-height: 240.893px;
	background-size: 105%;
	display: flex;
	border-radius: 8.736px;
	align-items: flex-end;
	padding-left: 12px;
	padding-bottom: 15px;
	.tidings-block__title{
		max-width: 215px;
	}
}
.tidings-block.block-big{
	padding-left: 28px;
	padding-top: 70px;
	padding-bottom: 25px;
	max-width: 100%;
	margin-bottom: 17px;
	.tidings-block__title{
		max-width: 314px;
	}
}
.tidings-block__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 17.472px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	max-width: 314px;
	margin-top: 3px;
	margin-bottom: 4px;
}
.tidings-block__text{
	color: rgba(255, 255, 255, 0.40);
	font-family: TT Interfaces;
	font-size: 12.231px;
	font-style: normal;
	font-weight: 400;
	line-height: 19.219px;
	max-width: 229px;
}
.tidings-block__link{
	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 12.231px;
	font-style: normal;
	font-weight: 600;
	line-height: 22.714px;
	margin-top: 9px;
	display: inline-block;
}
.tidings-block{
	

}
.tidings{
	
}
.community{
	border-radius: 18.732px;
	background: #161616;
	padding-top: 52.41px;
	padding-bottom: 60.29px;
}
.community__title{
	text-align: center;
	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 34.715px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%;
	letter-spacing: -1.389px;
	margin-bottom: 25px;
	text-transform: uppercase;

}
.community-block__title{
	color: #AFAFAF;
	font-family: TT Interfaces;
	font-size: 26.036px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%; /* 29.811px */
	letter-spacing: -1.041px;
	margin-bottom: 21px;
	margin-top: 21px;
}
.community-block{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 38px;
	border-radius: 18.732px;
	background: #262626;
	max-width: 191px;
	width: 100%;
	padding-bottom: 26.41px;
	margin-right: 10px;
	margin-left: 10px;
}
.community-row{
	justify-content: center;
}
.community-block-img{

}
.community-block__btn{
	display: inline-flex;
	align-items: center;
	border-radius: 4.339px;
	border: 0.434px solid #AFAFAF;
	width: 123.237px;
	height: 38.186px;
	justify-content: center;

	color: #AFAFAF;
	font-family: TT Interfaces;
	font-size: 17.357px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%; /* 20.829px */
	letter-spacing: -0.521px;
	svg{
		margin-left: 8.15px;
	}
}
.footer-logo__text{
	color: #666;
	font-family: TT Interfaces;
	font-size: 10.414px;
	font-style: normal;
	font-weight: 400;
	line-height: 13.018px; /* 125% */
	letter-spacing: -0.417px;
	max-width: 154px;
}
.footer__logo{
	max-width: 105px;
	display: inline-block;
	margin-bottom: 12px;
}
.footer__mail{
	margin-top: 67px;
	display: inline-block;
	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 17.357px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 22.565px */
	letter-spacing: -0.174px;
}
.footer-list__title{
	color: rgba(255, 255, 255, 0.60);
	font-family: TT Interfaces;
	font-size: 17.357px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 22.565px */
	letter-spacing: -0.174px;
	margin-bottom: 13.45px;
}
.footer-list ul{
	color: #666;
	font-family: TT Interfaces;
	font-size: 10.414px;
	font-style: normal;
	font-weight: 400;
	line-height: 13.018px;
	letter-spacing: -0.417px;
	margin-bottom: 30px;
	&:last-child{
		margin-bottom: 0;
	}
	li{
		margin-bottom: 8.5px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.footer-info{
	display: flex;
	align-items: flex-start;
}
.footer-list{
	margin-right: 95px;

}
.footer-soc__title{
	margin-bottom: 12px;
}
.footer-soc-icon{
	display: inline-flex;
	margin-right: 21px;
	&:last-child{
		margin-right: 0;
	}
}
.footer-list-last{
	margin-right: 60px;
}
.footer-soc-item{
	margin-bottom: 30px;
	&:last-child{
		margin-bottom: 0;
	}
}
.community{
	margin-bottom: 90px;
}
.footer{
	padding-bottom: 50px;
}
.locations__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35px;
	font-weight: 700;
	line-height: 114.5%;
	letter-spacing: -1.4px;
	text-transform: uppercase;
	max-width: 404px;
	margin-bottom: 5.77px;
}
.locations__subtitle{
	color: rgba(255, 255, 255, 0.60);
	font-family: TT Interfaces;
	font-size: 17.155px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	letter-spacing: -0.172px;
}
.locations-span{
	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 17.155px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 22.301px */
	letter-spacing: -0.172px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 234px;
	height: 45px;
	background-size: cover;
	background-position: center;
	background-image: url('../img/bg-blocks/map-bottom.svg');
	span{
		position: relative;
		top: 26px;
	}
}
.locations-info{
	width: 465px;
	height: 140px;
	background-size: cover;
	background-position: center;
	background-image: url('../img/bg-blocks/map-top.svg');
	position: absolute;
	top: 0px;
	left: 0px;
}
.locations-map{
	position: relative;
}
.locations-map-inner{
	border-radius: 21px;
}
.type-block-small{
	max-width: 291.24px;
	padding-left: 13.67px;
	padding-right: 13.08px;
	padding-top: 15px;
	padding-bottom: 9px;
	position: absolute;
	top: 12.6px;
	right: 13.43px;
	.type-block__time{
		max-width: 48.144px;
	}
	.type-block__title{
		font-size: 14.859px;
		margin-top: 3.5px;
		margin-bottom: 42.5px;
		line-height: 15.454px;
		letter-spacing: -0.297px;
	}
	.type-block-video{
		min-height: 91.533px;
		max-width: 128.978px;
	}
	.play-video{
		width: 29.718px;
		height: 29.718px;
	}
	.type-navslider{
		padding-bottom: 7.3px;
		margin-top: 6.5px;
	}
	.type-navslider__slide{
		max-width: 61.22px;
		min-height: 54.682px;
	}
	.type-block__where{
		font-size: 8.321px;
		line-height: 10.104px;
	}
	.type-block__where svg{
		max-width: 9.51px;
		margin-right: 6px;
	}
	.type-characteristics__title{
		font-size: 11.887px;
		line-height: 130%;
		margin-bottom: 10.5px;
	}
	.type-characteristics{
		margin-top: 11.29px;
	}
	.type-characteristics{
		padding-left: 5px;
	}
	.type-power{
		padding-left: 9px;
		padding-top: 5.35px;
	}
	.type-btn{
		max-width: 127.789px;
	}
	.type-btn span{
		font-size: 8.916px;
		line-height: 8.916px;
	}
	.list-circle{
		font-size: 8.321px;
		line-height: 10.104px;
	}
	.list-circle li{
		padding-left: 8px;
		margin-bottom: 4px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.list-circle li::before{
		width: 2.972px;
		height: 2.972px;
		background: #ED43E5;
		top: 3px;
	}
	.type-characteristics__title{
		max-width: 100px;
	}
	.type-power{
		padding-bottom: 8.5px;
		max-width: 128px;
	}
	.wrapper{
		max-width: 128px;
	}
	.type-characteristics__item{
		border-radius: 1.53px;
		padding-left: 2px;
		padding-top: 2px;
		padding-bottom: 2px;
		padding-right: 3px;
		margin-right: 3px;
		margin-bottom: 4px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.type-block-video{
		border-radius: 5.944px;
	}
	.type-block__title{
		margin-bottom: 28.5px;
	}
	.type-navslider__slide{
		border-radius: 5.944px;
	}
	.type-characteristics__item svg{
		margin-right: 3.33px;
	}
	.type-characteristics__item span{
		font-size: 7.141px;
		line-height: 8.671px; /* 121.429% */
		letter-spacing: -0.143px;
	}
	.type-characteristics__item strong{
		font-size: 4.167px;
		padding: 1.7px;
		letter-spacing: -0.083px;
	}
	.type-btns{
		margin-top: 10px;
	}

		border-radius: 5.944px;
		background: #393939;

}
.locations-map-inner{
	width: 100%;
}
.tournaments-slider{
	.section-title{
		margin-bottom: 0;
	}
}
.bridge{
	padding-left: 20px;
	padding-top: 20px;
	padding-bottom: 30px;
	padding-right: 47px;
	border-radius: 22px;
	.bridge-line{
		justify-content: space-between;
		width: 100%;
		margin-bottom: 156px;
	}
}
.bridge__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35px;
	font-weight: 700;
	line-height: 114.5%;
	letter-spacing: -1.4px;
	text-transform: uppercase;
	margin-bottom: 20px;
}
.bridge-item{
	border-radius: 14.279px;
	background: rgba(255, 255, 255, 0.10);
	display: flex;
	align-items: center;
	padding: 10px;
	padding-right: 21px;
	min-width: 360px;
	.icon{
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 14.279px;
		background: rgba(255, 255, 255, 0.10);
		margin-right: 20px;
		width: 80px;
		height: 80px;
	}
}
.bridge-item__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%;
	letter-spacing: -1.4px;
	text-transform: uppercase;
	margin-bottom: 1px;
}
.bridge-item__text{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
}
.bridge-list{
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: #FFF;
	li{
		margin-bottom: 10px;
		padding-left: 29px;
		position: relative;
		&::after{
			position: absolute;
			content: '';
			display: block;
			width: 14px;
			height: 14px;
			background-size: cover;
			left: 0px;
			top: 6px;
			background-image: url('../img/icons/bridge-icons.svg');
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.cybersport__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%; /* 40.075px */
	letter-spacing: -1.4px;
	text-transform: uppercase;
	margin-bottom: 12px;
}
.cybersport__text{
	color: rgba(255, 255, 255, 0.40);
	font-family: TT Interfaces;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 19.706px;
}
.cybersport-slider{
	margin-top: 26px;
	display: flex;
	flex-wrap: nowrap;
	overflow-x: scroll;
	&::-webkit-scrollbar {
	  height: 0px;
	}
}
.cybersport-slide{
	min-width: 141px;
	height: 263px;
	padding-left: 10px;
	padding-top: 10px;
	margin-right: 20px;
	max-width: 141px;
	border-radius: 13px;
	&:last-child{
		margin-right: 0;
	}
}
.cybersport-slide__name{
	color: #000;
	font-family: TT Interfaces;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px; /* 100% */
	text-transform: uppercase;
	padding: 7px 15px;
	border-radius: 3px;
	background: #6FFF8B;
	display: inline-block;
}
.tournaments-slide{
	display: flex!important;
}
.tournaments-wrapper{
	position: relative;
}
.tournaments-arrows{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	z-index: 1;
	left: 0px;
	right: 0px;
}
.tournaments-arrow{
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	background-image: url('../img/icons/tournaments-arrow.svg');
}
.tournaments-wrapper{
	.arrow-next{
		background-image: url('../img/icons/tournaments-arrow-right.svg');
	}
}
.calendar-item{
	cursor: pointer;
}
.calendar-list{
	max-width: 511px;
	width: 100%;
}
.calendar-item{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-radius: 5px;
	background: #393939;
	padding-left: 20px;
	padding-right: 19px;
	padding-top: 11px;
	padding-bottom: 11px;
	margin-bottom: 8px;
	&:last-child{
		margin-bottom: 0;
	}
	.icon{
		display: flex;
		margin-right: 10px;
		path{
			fill: #666666;
			transition: .4s all ease;
		}
	}
}
.calendar-item__name{
	color: #000;
	font-family: TT Interfaces;
	font-size: 14px;
	font-weight: 400;
	color: #FFF;
	transition: .4s all ease;
	strong{
		font-weight: 600;
	}
}
.calendar-item__date{
	color: #666;
	font-family: TT Interfaces;
	font-size: 14px;
	font-weight: 600;
	transition: .4s all ease;
}
.calendar-item.active{
	background: #FFF;
	.calendar-item__name{
		color: #000;
	}
	.icon{
		path{
			fill: #000000;
		}
	}
	.calendar-item__date{
		color: #000;
	}
}
.calendar-block-slider{
	width: 100%;
	max-width: 312px;
}
.calendar-block{
	background: #313131;
	padding-top: 7.14px;
	padding-left: 6.43px;
	padding-right: 6.43px;
	padding-bottom: 7.32px;
	border-radius: 20px;
	.calendar-block-photo{
		width: 100%;
		margin-bottom: 11px;
	}
	.type-block{
		position: static;
		padding: 0px;
		background: transparent;
		max-width: 100%;
		padding-top: 18px;
		border-top: 1px solid #454545;
		border-radius: 0px;
		margin-top: 17px;
	}
	.type-navslider{
		padding-bottom: 0;
		margin-bottom: 0;
		border: none;
	}
	.type-navslider__slide{
		max-width: 69.207px;
		border-radius: 6.719px;
		min-height: 61.816px;
	}
	.type-block-video{
		border-radius: 6.719px;
		max-width: 145.804px;
		min-height: 103.474px;
	}
	.type-block-small .play-video{
		width: 33.596px;
		height: 33.596px;
	}
	.type-block__time{
		max-width: 54.425px;
	}
	.type-block__title{
		font-size: 16.798px;
		line-height: 17.47px;
		margin-top: 0px;
		margin-bottom: 25px;
		letter-spacing: -0.336px;
	}
}
.tidings{
	padding-top: 20px;
	.main-top{
		justify-content: flex-end;
		margin-bottom: 0;
	}
}
.news-all__item{
	display: flex;
	align-items: center;
}
.news-all__date{
	color: #666;
	font-family: TT Interfaces;
	font-size: 8.957px;
	font-style: normal;
	font-weight: 400;
	line-height: 108%;

	border-radius: 3.897px;
	opacity: 0.7;
	background: rgba(255, 255, 255, 0.11);
	padding: 4px 5px;
	margin-right: 5px;
}
.news-all__title{
	color: rgba(255, 255, 255, 0.60);
	font-family: TT Interfaces;
	font-size: 17.915px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%; 
}
.news-all__item{
	margin-bottom: 24px;
	&:last-child{
		margin-bottom: 0;
	}
}
.news-all{
	padding-left: 12px;
}
.news-all--page{
	margin-top: -20px;
	margin-bottom: 75px;
}
.application{
	.main-top{
		margin-bottom: 0;
		justify-content: flex-end;
	}
}
.application__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35.887px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%; /* 41.09px */
	letter-spacing: -1.435px;
	text-transform: uppercase;
	max-width: 467px;
	margin-bottom: 7px;
}
.application__subtitle{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 27px;
	max-width: 293px;
}
.application-list{
	color: rgba(255, 255, 255, 0.60);
	font-family: TT Interfaces;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.32px;
	margin-top: 11px;
	li{
		margin-bottom: 7px;
		padding-left: 15px;
		position: relative;
		&::after{
			position: absolute;
			top: 0.6px;
			content: '•';
			left: 0px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.application-btn{
	display: inline-flex;
	margin-right: 12px;
	&:last-child{
		margin-right: 0;
	}
}
.application-btns{
	margin-top: 33px;
}
.application{
	.row{
		position: relative;
	}
}
.application-phone{
	position: absolute;
	right: -35px;
	top: 10px;
}
.contacts-reservation{
	.main-top{
		.section-title{
			margin-bottom: 0;
			position: relative;
			top: 5px;
		}
	}
}
.contacts-reservation-block{
	.content{
		padding-left: 30px;
		padding-top: 21px;
	}
}
.contacts-reservation__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35.887px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%; /* 41.09px */
	letter-spacing: -1.435px;
	text-transform: uppercase;
	max-width: 460px;
}
.contacts-reservation-line{
	margin-top: 89px;
	display: flex;
	align-items: flex-start;
}
.contacts-reservation-item__title{
	display: flex;
	align-items: center;

	color: rgba(255, 255, 255, 0.50);
	font-family: TT Interfaces;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	margin-bottom: 11px;
	img{
		margin-right: 10px;
	}
}
.contacts-reservation-item__phone{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.contacts-reservation-item{
	margin-right: 37px;
	&:last-child{
		margin-right: 0;
	}
}
.nav-reservation-contacts{
	margin: 0px;
}
.contacts-reservation-soc{
	display: flex;
	align-items: center;
}
.contacts-reservation-soc__link{
	margin-right: 6px;
	&:last-child{
		margin-right: 0;
	}
}
.contacts{
	.section-title{
		margin-bottom: 20px;
	}
}
.contacts-block{
	.content{
		padding-top: 25px;
		padding-left: 30px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}
.contacts-block__title{
	color: rgba(255, 255, 255, 0.33);
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	max-width: 152px;
	margin-bottom: 16px;
	min-height: 52px;
}
.contacts-block__mail{
	display: inline-flex;
	align-items: center;
	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	svg{
		margin-right: 10px;
	}
	span{
		border-bottom: 1px solid #6FFF8B;
		line-height: 90%;
	}
}
.contacts-block__phone{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 11px;
	svg{
		margin-right: 12px;
	}
}
.offers__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35px;
	font-weight: 700;
	line-height: 114.5%;
	letter-spacing: -1.4px;
	text-transform: uppercase;
	max-width: 337px;
	margin-bottom: 12px;
}
.offers{
	position: relative;
	.content{
		padding-left: 44px;
		padding-top: 81px;
		padding-bottom: 39px;
		
	}
}
.team{
	.section-title{
		margin-bottom: 0;
		position: relative;
		top: 3px;
	}
	.main-top{
		margin-bottom: 40px;
	}
}
.team__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 36px; /* 120% */
	letter-spacing: 0.6px;
	margin-bottom: 15px;
	text-transform: uppercase;
}
.team-block{

	.content{
		padding-left: 30px;
		padding-bottom: 25px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
	}
}
.team-block__title{
	color: #6FFF8B;
	font-family: TT Interfaces;
	font-size: 46px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.team-block__subtext{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
}
.news-vacancy{
	.news-slide{
		background-image: url('../img/bg-blocks/news-vacan.svg');
		min-height: 417.737px;
	}
	.news-slide__title{
		line-height: 130%;
		margin-bottom: 15px;
	}
	.news-slide .height-wrapper{
		height: auto;
	}
	.content{
		padding-left: 34px;
	}
	.news-slide__more{
		font-weight: 600;
	}
}
.vacancy-requirements__title{
	color: rgba(255, 255, 255, 0.40);
	font-family: TT Interfaces;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 17px;
	margin-bottom: 10px;
}
.vacancy-requirements-list{
	color: rgba(255, 255, 255, 0.40);
	font-family: TT Interfaces;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	padding-left: 10px;
	li{
		padding-left: 14px;
		position: relative;
		&::after{
			position: absolute;
			content: '';
			display: block;
			width: 4px;
			height: 4px;
			background: #ED43E5;
			border-radius: 50%;
			left: 0px;
			top: 10.5px;
		}
	}
}
.news-vacancy{
	padding-bottom: 0;
	border-bottom: none;
}
.news-single{
	.main-top{
		justify-content: flex-start;
	}
}
.page-bread--big{
	font-size: 20px;
	a{
		padding-right: 16px;
		margin-right: 10px;
		&::after{
			top: 55%;
			transform: translateY(-50%);
			right: 0px;

		}
	}
}
.news-single-content{
	max-width: 629px;
	width: 100%;
}
.news-single-photo{
	width: 100%;
	min-height: 246px;
	border-radius: 12px;
	padding-left: 29px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	padding-bottom: 20px;
	margin-bottom: 21px;
}
.news-single__date{
	padding: 5px 7px;
	display: inline-block;
	color: #666;
	font-family: TT Interfaces;
	font-size: 12.164px;
	line-height: 100%;
	border-radius: 5.292px;
	background: rgba(255, 255, 255, 0.11);
	opacity: .7;
	margin-bottom: 4px;
}
.news-single-text{
	padding-left: 23px;
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 35px;
	p{
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.news-single__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 24.328px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
}
.news-single-video{
	width: 100%;
	min-height: 290px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.news-single-video__play{
	display: inline-flex;
}
.news-all-title{
	color: rgba(255, 255, 255, 0.60);
	font-family: TT Interfaces;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 30px;
	line-height: normal;
}
.partnership__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 35.887px;
	font-style: normal;
	font-weight: 700;
	line-height: 114.5%; /* 41.09px */
	letter-spacing: -1.435px;
	text-transform: uppercase;
	margin-bottom: 17px;
}
.main-top--right{
	justify-content: flex-end;
}
.partnership-block{
	display: inline-flex;
	.content{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		padding-bottom: 25px;
		padding-left: 30px;
	}
}
.partnership-block__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
}
.partnership-block--big{
	.content{
		padding-bottom: 28px;
		padding-left: 21px;
	}
}
.partnership-block-list{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	margin-bottom: 20px;
	li{
		margin-bottom: 10px;
		padding-left: 18px;
		position: relative;
		&::after{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-size: cover;
			background-position: center;
			left: 0px;
			content: '';
			width: 8px;
			height: 8px;
			background-image: url('../img/icons/partnership-list.svg');
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.partnership-block{
	margin-bottom: 14px;
}
.birthday-block{
	display: inline-flex;
	margin-bottom: 15px;
	&:last-child{
		margin-bottom: 0;
	}
	.content{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		padding-left: 20px;
		padding-bottom: 20px;
	}
}
.birthday-block__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 21px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px; /* 123.81% */
	letter-spacing: -0.42px;
	max-width: 154px;
}
.birthday-block--right{
	.content{
		align-items: flex-end;
		padding-right: 28px;
	}
}
.birthday-block--big{
	.birthday-block__title{
		max-width: 272px;
		font-size: 20px;
		line-height: 20px;
	}
	.line{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding-right: 24px;
	}
}
.birthday-block__subtitle{
	color: rgba(255, 255, 255, 0.50);
	font-family: TT Interfaces;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin-top: 7px;
}
.birthday{
	.partnership__title{
		margin-bottom: 30px;
	}
}
.individual{
	padding-top: 30px;
	padding-left: 30px;
	padding-bottom: 35px;
	padding-top: 26px;
	border-radius: 22px;
	background-size: 103%;
	padding-right: 26px;
	.partnership__title{
		max-width: 662px;
		margin-bottom: 20px;
	}
}
.individual__subtitle{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 10px;
}
.individual-list{
	font-size: 16px;
	line-height: 22px; /* 137.5% */
	letter-spacing: -0.32px;
	color: rgba(255, 255, 255, 0.60);
	margin-bottom: 27px;
	&:last-child{
		margin-bottom: 0;
	}
	li{
		margin-bottom: 7px;
		position: relative;
		padding-left: 15px;
		&::after{
			position: absolute;
			left: 0px;
			top: 0.5px;
			content: '•';
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.individual-block{
	border-radius: 18.732px;
	background: rgba(38, 38, 38, 0.70);
	backdrop-filter: blur(17px);
	max-width: 399px;
	padding-left: 20px;
	padding-bottom: 29px;
	padding-top: 21px;
	padding-right: 26px;
}
.individual-block__title{
	color: #FFF;
	font-family: TT Interfaces;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 9px;
}
.individual-block__text{
	color: rgba(255, 255, 255, 0.60);
	font-family: TT Interfaces;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 137.5% */
	letter-spacing: -0.32px;
}
.individual-bottom{
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.partners-nav{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.partners{
	.partnership__title{
		margin-bottom: 24px;
	}
}
.partners-nav{
	margin-bottom: 50px;
}
.partners-block{
	.content{
		padding-top: 45px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 10px;
		padding-right: 10px;

	}
}
.partners-slide{
	width: 100%;
	border-radius: 12px;
	background-color: #161629;
	min-height: 160px;
	display: block;
}
.partners-slider{
	width: 100%;
}
.partners-slider-wrapper{
	width: 100%;
	margin-top: 20px;
	margin-bottom: 8px;
	position: relative;
}
.partners-navslider__slide{
	border-radius: 8px;
	background: #161629;
	width: 78px;
	height: 60px;
	cursor: pointer;
}
.partners-slider-arrow{
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 3.861px;
	background: #6FFF8B;
	width: 30px;
	height: 30px;
	left: 10px;
}
.partners-slider-arrow.arrow-next{
	right: 10px;
	left: unset;
}
.partners-block__img{
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.offers-partnership{
	.content{
		padding-left: 100px;
		padding-top: 49px;
	}
	.offers__title{
		margin-bottom: 26px;
	}
}
.cybersport{
	margin-right: -20px;
}
.about{
	.main-top{
		margin-bottom: 50px;
		.section-title{
			margin-bottom: 0;
			position: relative;
			top: 5px;
		}
	}
}
.about-block{
	max-width: 271px;
	width: 100%;
	.content{
		padding-left: 12px;
	}
}
.about-block-photo{
	width: 100%;
	min-height: 247px;
	border-radius: 9px;
	background: #535353;
	margin-bottom: 16px;
}
.about-block__title{
	color: #FFF;
	font-size: 17.98px;
	font-weight: 600;
	line-height: 130%
}
.about-block__subtitle{
	color: rgba(255, 255, 255, 0.40);
	font-size: 12.586px;
	font-weight: 400;
	line-height: 19.778px;
}
.biggest-wrapper{
	background: #232323;
	padding-left: 20px;
	padding-top: 18px;
	padding-bottom: 26px;
	padding-right: 19px;
	padding-right: 19px;
	border-radius: 35px;
	.row{
		margin-bottom: -10px;
	}
}
.biggest-block{
	min-height: 179.56px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	padding-bottom: 16px;
	padding-left: 18px;
	max-width: 399.02px;
	margin-bottom: 10px;
	overflow: hidden;
	width: 100%;
	position: relative;
	background-size: 101%;
	border-radius: 9.541px;
	&::after{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		display: block;
		border-radius: 9.541px;
		background: linear-gradient(79deg, #000 0%, rgba(0, 0, 0, 0.00) 99.04%);
	}
}
.biggest-block__title{
	font-size: 17.093px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	color: #FFF;
	position: relative;
	z-index: 1;
	max-width: 197px;
	margin-bottom: 5px;
}
.biggest-block__subtitle{
	color: rgba(255, 255, 255, 0.40);
	font-size: 11.965px;
	font-style: normal;
	font-weight: 400;
	line-height: 15.266px;
	position: relative;
	z-index: 1;
	max-width: 240px;
}
.biggest-block-small{
	max-width: 262px;
	&::after{
		right: unset;
		max-width: 166px;
		background: linear-gradient(79deg, #000 0%, rgba(0, 0, 0, 0.00) 99.04%);
	}
	.biggest-block__subtitle{
		max-width: 150px;
	}
}
.biggest-block-big{
	max-width: 100%;
	&::after{
		background: linear-gradient(79deg, #000 0%, rgba(0, 0, 0, 0.00) 99.04%);
	}
	.biggest-block__title{
		max-width: 316px;
		margin-bottom: 8px;
	}
	.biggest-block__subtitle{
		max-width: 300px;
		p{
			margin-bottom: 3px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}
.massmedia-block{
	max-width: 271px;
	width: 100%;
	.content{
		padding-left: 12px;
	}
}
.massmedia-block-photo{
	margin-bottom: 19px;
	width: 100%;
	min-height: 248px;
	border-radius: 9px;
}
.massmedia-block__date{
	color: #666;
	font-size: 8.99px;
	font-weight: 400;
	line-height: 108%;
	border-radius: 3.911px;
	background: rgba(255, 255, 255, 0.11);
	display: inline-block;
	padding: 4.5px 5px;
	background: #2D2D2D;
	margin-bottom: 3px;
}
.massmedia-block__title{
	color: #FFF;
	margin-top: 2.7px;
	margin-bottom: 6.4px;
	font-size: 17.98px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	max-width: 223px;
}
.massmedia-block__subtext{
	color: rgba(255, 255, 255, 0.40);
	font-size: 12.586px;
	margin-bottom: 9.44px;
	max-width: 244px;
	font-weight: 400;
	line-height: 19.778px;
}
.massmedia-block__number{
	color: #6FFF8B;
	font-size: 12.586px;
	font-weight: 600;
	line-height: 23.374px;
}
.partners-about-slide{
	display: flex!important;
	align-items: center;
	justify-content: center;
	max-width: 184px;
	width: 100%;
	border-radius: 10px;
	height: 100px;
	background: #232323;
	margin-right: 7px;
	margin-left: 7px;
}
.partners-about{
	margin-right: -20px;
}
.height{
	.partnership__title{
		text-transform: none;
	}
}
.height-block__title{
	color: #FFF;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	margin-bottom: 5px;
}
.height-block__text{
	color: rgba(255, 255, 255, 0.60);
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.32px;
	max-height: 744px;
}
.height-block{
	padding-left: 14px;
	padding-right: 14px;
	padding-top: 11px;
	padding-bottom: 22px;
	background: #161616;
	border-radius: 30px;
	margin-bottom: 13px;
	width: 100%;
	position: relative;
	&::after{
		position: absolute;
		background-image: url('../img/bg-blocks/architec-height.svg');
		width: 63px;
		content: '';
		display: block;
		bottom: 0px;
		right: 0px;
		background-size: cover;
		height: 62px;
	}
	.content{
		margin-top: 20px;
		padding-left: 15px;
	}
	.height-block-img{
		width: 100%;
	}
}
.height-block-small{
	max-width: 407px;
}
.partners-slider{
	pointer-events: none;
}
.height-row{
	margin-bottom: -13px;
	align-items: stretch;
}
.height{
	margin-bottom: 100px;
}
.offers__subtitle{
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 20px;
}
.offers-about{
	display: flex;
	margin-bottom: 100px;
	.offers__title{
		margin-bottom: 10px;
		font-weight: 600;
	}
	.content{
		padding-left: 100px;
		padding-top: 49px;
	}
}
.offers-img{
	position: absolute;
	bottom: 6px;
	left: 366px;
}
.contacts-about{
	margin-bottom: 30px;
}
.arenas{
	margin-right: -20px;
	.type-block{
		position: static;
		min-width: 291.24px;
		margin-right: 23px;
		background: rgba(217, 217, 217, 0.10);
	}
}
.arenas-row{
	display: flex;
	align-items: stretch;
	overflow-x: scroll;
	padding-bottom: 20px;
	&::-webkit-scrollbar {
	  height: 6px;
	  cursor: pointer;
	}

	&::-webkit-scrollbar-track {
	  border-radius: 10px;
	  background: rgba(255, 255, 255, 0.04);
	  margin-right: 20px;
	}

	&::-webkit-scrollbar-thumb {
	  border-radius: 10px;

	  background: #6FFF8B;
	}
}
a{
	transition: .4s all ease;
	&:hover{

	}
}
.footer-list ul{
	a{
		&:hover{
			color: #6FFF8B;
		}
	}
}
.footer__mail{
	&:hover{
		color: #6FFF8B;
		opacity: .8;
	}
}
.community-block__btn{
	transition: .4s all ease;
	&:hover{
		color: #6FFF8B;
		rect{
			fill: #6FFF8B;
		}
	}
	svg{
		*{
			transition: .4s all ease;
		}
	}
}
.tidings-block__link, .massmedia-block__number{
	&:hover{
		color: #6FFF8B;
		opacity: .8;
	}
}
.type-block__where{
	&:hover{
		color: #6FFF8B;
		opacity: .8;
	}
}
.tidings__all{
	&:hover{
		color: #6FFF8B;
		opacity: .8;
	}
}
.tidings-category{
	&:hover{
		background: #6FFF8B;
		color: #000;
	}
}
.nav-btn, .type-btn, .nav-reservation, .first-screen-btn{
	&:hover{
		opacity: .8;
	}
}
.nav-btn--dark{
	&:hover{
		color: #797979;
	}
}
.info-soc{
	.info-soc__link{
		&:nth-child(1){
			&:hover{
				svg{
					rect{
						opacity: 1;
						fill: #6FFF8B;
					}
					
				}
			}
		}
		&:nth-child(3){
			&:hover{
				svg{
					rect{
						
						fill: #000;
					}
					
				}
			}
		}
	}
}

.contacts-block__phone{
	&:hover{
		color: #6FFF8B;
	}
}
.contacts-block__mail{
	span{
		transition: .4s all ease;
	}
	&:hover{
		color: #6FFF8B;
		span{
			border-color: transparent;
		}
	}
}


.fresh-block__more, .cities-block__more, .main-block__more{
	&:hover{
		color: var(--CBR-GREEN, #00FF60);
		opacity: .8;
	}
}
.price-btn{
	&:hover{
		color: #000;
	}
}
.price-block-black .price-btn{
	&:hover{
		color: #fff;
	}
}
.page-top{
	display: none;
}
.page-row{
	padding-top: 20px;
}
.nav{
	position: fixed;
	max-width: 260px;
}
.top-info{
	z-index: 10000;
	position: fixed;
	max-width: 850px;
	top: 0px;
	background: #121212;
	padding-top: 20px;
}
.page-right-wrapper{
	position: relative;
}
.page-right-wrapper{
	padding-top: 67px;
}
.page-row{
	padding-top: 0;
	margin-top: 0px;
}
.top-info{
	
}
.tidings-block, .community-block, .footer__logo, .cities-block, .arena-block, .fresh-block, .calendar-item, .type-btn, .single-play, .single-gallery__item, .btn-figure{
	transition: .4s all ease;
	&:hover{
		transform: scale(1.025);
	}

}
.footer-soc-icon{
	transition: .4s all ease;
	&:hover{
		transform: scale(1.2);
	}
}
.cities-block{
	.cities-block__more{
		&:hover{
			opacity: 1;
		}
	}
}

.news-slide__more{
	transition: .4s all ease;
	&:hover{
		color: #6FFF8B;
	}
}


.modal {
	--bs-modal-zindex: 1055;
	--bs-modal-width: 500px;
	--bs-modal-padding: 1rem;
	--bs-modal-margin: 0.5rem;
	--bs-modal-color: ;--bs-modal-bg:#fff;
	--bs-modal-border-color: var(--bs-border-color-translucent);
	--bs-modal-border-width: 1px;
	--bs-modal-border-radius: 0.5rem;
	--bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--bs-modal-inner-border-radius: calc(0.5rem - 1px);
	--bs-modal-header-padding-x: 1rem;
	--bs-modal-header-padding-y: 1rem;
	--bs-modal-header-padding: 1rem 1rem;
	--bs-modal-header-border-color: var(--bs-border-color);
	--bs-modal-header-border-width: 1px;
	--bs-modal-title-line-height: 1.5;
	--bs-modal-footer-gap: 0.5rem;
	--bs-modal-footer-bg: ;--bs-modal-footer-border-color:var(--bs-border-color);
	--bs-modal-footer-border-width: 1px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--bs-modal-zindex);
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: var(--bs-modal-margin);
	pointer-events: none;
}

.modal.fade .modal-dialog {
	transition: transform .3s ease-out;
	transform: translate(0,-50px);
}

@media (prefers-reduced-motion:reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}

.modal.show .modal-dialog {
	transform: none;
}

.modal.modal-static .modal-dialog {
	transform: scale(1.02);
}

.modal-dialog-scrollable {
	height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
	max-height: 100%;
	overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	color: var(--bs-modal-color);
	pointer-events: auto;
	background-color: var(--bs-modal-bg);
	background-clip: padding-box;
	border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
	border-radius: var(--bs-modal-border-radius);
	outline: 0;
}

.modal-backdrop {
	--bs-backdrop-zindex: 1050;
	--bs-backdrop-bg: #000;
	--bs-backdrop-opacity: 0.5;
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--bs-backdrop-zindex);
	width: 100vw;
	height: 100vh;
	background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: var(--bs-backdrop-opacity);
}

.modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: var(--bs-modal-header-padding);
	border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
	border-top-left-radius: var(--bs-modal-inner-border-radius);
	border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
	padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
	margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: var(--bs-modal-title-line-height);
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: var(--bs-modal-padding);
}

.modal-footer {
	display: flex;
	flex-shrink: 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
	background-color: var(--bs-modal-footer-bg);
	border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
	border-bottom-right-radius: var(--bs-modal-inner-border-radius);
	border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
	margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width:576px) {
	.modal {
		--bs-modal-margin: 1.75rem;
		--bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}

	.modal-dialog {
		max-width: var(--bs-modal-width);
		margin-right: auto;
		margin-left: auto;
	}

	.modal-sm {
		--bs-modal-width: 300px;
	}
}

@media (min-width:992px) {
	.modal-lg,.modal-xl {
		--bs-modal-width: 800px;
	}
}

@media (min-width:1200px) {
	.modal-xl {
		--bs-modal-width: 1140px;
	}
}

.modal-fullscreen {
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0;
}

.modal-fullscreen .modal-content {
	height: 100%;
	border: 0;
	border-radius: 0;
}

.modal-fullscreen .modal-footer,.modal-fullscreen .modal-header {
	border-radius: 0;
}

.modal-fullscreen .modal-body {
	overflow-y: auto;
}

@media (max-width:575.98px) {
	.modal-fullscreen-sm-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}

	.modal-fullscreen-sm-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}

	.modal-fullscreen-sm-down .modal-footer,.modal-fullscreen-sm-down .modal-header {
		border-radius: 0;
	}

	.modal-fullscreen-sm-down .modal-body {
		overflow-y: auto;
	}
}

@media (max-width:767.98px) {
	.modal-fullscreen-md-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}

	.modal-fullscreen-md-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}

	.modal-fullscreen-md-down .modal-footer,.modal-fullscreen-md-down .modal-header {
		border-radius: 0;
	}

	.modal-fullscreen-md-down .modal-body {
		overflow-y: auto;
	}
}

@media (max-width:991.98px) {
	.modal-fullscreen-lg-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}

	.modal-fullscreen-lg-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}

	.modal-fullscreen-lg-down .modal-footer,.modal-fullscreen-lg-down .modal-header {
		border-radius: 0;
	}

	.modal-fullscreen-lg-down .modal-body {
		overflow-y: auto;
	}
}

@media (max-width:1199.98px) {
	.modal-fullscreen-xl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}

	.modal-fullscreen-xl-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}

	.modal-fullscreen-xl-down .modal-footer,.modal-fullscreen-xl-down .modal-header {
		border-radius: 0;
	}

	.modal-fullscreen-xl-down .modal-body {
		overflow-y: auto;
	}
}

@media (max-width:1399.98px) {
	.modal-fullscreen-xxl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}

	.modal-fullscreen-xxl-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}

	.modal-fullscreen-xxl-down .modal-footer,.modal-fullscreen-xxl-down .modal-header {
		border-radius: 0;
	}

	.modal-fullscreen-xxl-down .modal-body {
		overflow-y: auto;
	}
}

.modal-dialog button {
	border: none;
	background-color: transparent;
}

.modal-body {
	padding: 0;
}

.modal-content {
	border: none;
	position: relative;
	border-radius: 20px;
	background: #252729;
}

.modal-body {
	overflow: hidden;
}

.modal-dialog .close {
	padding-right: 20px;
	padding-top: 15px;
	align-self: flex-end;
	max-width: fit-content;
	background: transparent;
}
.callback-modal__modal-dialog {
	max-width: 413px;
}

.callback-modal__modal-dialog textarea {
	height: 110px;
	margin-bottom: 10px;
	padding-top: 15px;
	resize: none;
}

@media (max-width: 767.98px) {
	.modal-dialog {
		margin-left: auto;
		margin-right: auto;
	}
}
.formblock {
	padding: 49px;
	padding-top: 10px;
}
.formblock__title {
	color: #fff;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: calc(36 / 30 * 100%); /* 120% */
	letter-spacing: 0.6px;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.formblock__subtitle {
	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	opacity: 0.5;
	margin-bottom: 34px;
}

.form-control {
	height: 60px;
	border-radius: 10px;
	width: 100%;
	background: rgba(255, 255, 255, 0.07);
	color: rgba(255, 255, 255, 0.4);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 157.143%;
	border: none;
	padding-left: 20px;
}

.form-control::placeholder {
	color: rgba(255, 255, 255, 0.4);
}

.main-form__button {
	width: 100%;
	color: #000;
	text-align: center;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background-image: url(../img/button.svg);
	background-size: 100% 100%;
	height: 60px;
}
.form-submit .invisible {
	display: none;
}

.form-control:focus {
	background: rgba(255, 255, 255, 0.07);
	color: rgba(255, 255, 255, 0.4);
}

.main-form__button {
	margin-top: 15px;
	margin-bottom: 20px;
}

@media (max-width: 479.98px) {
	.formblock__title {
		font-size: 25px;
	}
	.formblock__inner {
		max-width: 294px;
	}
	.formblock {
		padding: 30px;
		padding-top: 10px;
	}
	.modal-dialog {
		max-width: fit-content;
	}

	.formblock__subtitle {
		margin-bottom: 20px;
	}
}

@media (max-width: 374.98px) {
	.formblock {
		padding: 20px;
		padding-top: 10px;
	}
}
.games-modal__inner {
	width: 100%;
}
.games-modal__container {
	display: flex;
	gap: 46.48px;
	align-items: flex-start;
	padding: 40px 50px 50px 30.48px;
	width: 100%;
}

.games-modal__truegames {
	align-items: flex-end;
	margin-top: auto;
}

.games-modal__title {
	color: #fff;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
	letter-spacing: 0.6px;
	text-transform: uppercase;
}

._green {
	color: #6fff8b;
}
.games-modal__list {
	padding-left: 0;
}
.games-modal__lists {
	margin-top: 15px;
	display: grid;
	grid-template-columns: repeat(3, auto);
	gap: 20px;
}

.games-modal__modal-dialog {
	max-width: 1160px;
}

.games-modal__pic {
	max-width: fit-content;
	margin-top: 50px;
	margin-left: auto;
}
.games-modal__item {
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 162.5%;
}

.games-modal__truegames_mob {
	display: none;
}

@media (max-width: 991.98px) {
	.games-modal__container {
		gap: 20px;
	}
	.games-modal__modal-dialog {
		max-width: 95%;
	}
	.games-modal__container {
	}
	.games-modal__truegames {
		display: none;
	}
	.games-modal__truegames_mob {
		display: flex;
		padding-bottom: 30px;

		justify-content: center;
	}
	.games-modal__pic {
		margin-inline: auto;
		margin-top: 20px;
	}
	.games-modal__container {
		padding: 30px;
	}
	.games-modal__lists {
		gap: 15px;
	}
}

@media (max-width: 991.98px) {
	.games-modal__lists {
		grid-template-columns: repeat(2, 1fr);
		gap: 0;
	}
	.games-modal__list {
		display: contents;
	}
	.games-modal__container {
		padding: 20px;
	}
	.games-modal__lists {
		grid-template-columns: repeat(2, auto);
	}
	.games-modal__item {
		font-size: 14px;
	}
}
@media (max-width: 768px) {
}
@media (max-width: 578px) {
	.games-modal__title {
		font-size: 23px;
	}
	.games-modal__lists {
		grid-template-columns: auto;
	}
	.games-modal__truegames {
		padding-inline: 15px;
	}
}
.open-select{
	position: relative;
	&:hover{
		.open-select-wrapper{
			opacity: 1;
			visibility: visible;
		}
	}
}
.open-select-wrapper{
	position: absolute;
	right: 0px;
	top: 100%;
	padding-top: 15px;
	transition: .4s all ease;
	opacity: 0;
	visibility: hidden;
}
.open-select-item{
	color: #8F8F8F;
	position: relative;
	font-family: TT Interfaces;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 2px;
	padding-bottom: 2px;
	background: #393939;
	border-radius: 8px;
	display: block;
	&::after{
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		content: '';
		display: block;
		background-size: cover;
		background-position: center;
		width: 9px;
		height: 9px;
		background-image: url('../img/open-arrow.svg');
		top: -5px;
	}
}
.language-selected-wrapper{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}
.language-selected{
	position: relative;
}
.language-selected-select{
	position: relative;
	cursor: pointer;
	&:hover{
		
	}
}
.language-selected-wrapper{
	transition: .4s all ease;
	opacity: 0;
	visibility: visible;
}
.language-select .language-selected::after{
	transition: .4s all ease;
}
.language-selected{
	&:hover{
		&::after{
			transform: scale(1, -1);
		}
		.language-selected-wrapper{
			opacity: 1;
			visibility: visible;
		}
	}
}
.modal-window{
	position: fixed;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	z-index: 1000000000;
	background: rgba(0,0,0,0.7);
	padding: 40px;
	overflow-y: scroll;
	display: none;
}
.modal-window__inner{
	border-radius: 10px;
	background: #252525;
	position: relative;
	height: max-content;
	.type-block{
		margin-bottom: 0;
	}
	.type-btn{
		width: 100%;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
		img{
			width: 100%;
		}
	}
}
.modal-window__close{
	position: absolute;
	top: 15px;
	right: 15px;
}
.modal-window__inner .type-btn span{
	font-size: 18px;
}
.modal{
	background: rgba(0,0,0,0.7);
}
.modal-dialog{
	height: 100%;
	margin-bottom: 0;
}
.games-modal__modal-content{
	height: max-content;
}
.games-modal{
	overflow-y: scroll;
	padding-top: 50px;
	padding-bottom: 50px;
	.modal-dialog-centered{
		align-items: unset;
		margin-top: 0;
		height: max-content;
		transform: unset!important;
	}
	.games-modal__modal-content{
		margin-top: auto;
		margin-bottom: auto;
	}
}
.modal-reservation{
	justify-content: center;
	.modal-window__inner{
		margin-top: auto;
		margin-bottom: auto;
		background: transparent;
	}
	.modal-window__close{
		right: -30px;
		top: -30px;
	}
}
.modal-window__close{
	cursor: pointer;
}