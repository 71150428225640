@font-face {
    font-family: 'TT Interfaces';
    src: url('../fonts/TTInterfaces-Regular.woff2') format('woff2'),
        url('../fonts/TTInterfaces-Regular.woff') format('woff'),
        url('../fonts/TTInterfaces-Regular.svg#TTInterfaces-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Interfaces';
    src: url('../fonts/TTInterfaces-SemiBold.woff2') format('woff2'),
        url('../fonts/TTInterfaces-SemiBold.woff') format('woff'),
        url('../fonts/TTInterfaces-SemiBold.svg#TTInterfaces-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Interfaces';
    src: url('../fonts/TTInterfaces-Bold.woff2') format('woff2'),
        url('../fonts/TTInterfaces-Bold.woff') format('woff'),
        url('../fonts/TTInterfaces-Bold.svg#TTInterfaces-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Interfaces Med';
    src: url('../fonts/TTInterfaces-Medium.woff2') format('woff2'),
        url('../fonts/TTInterfaces-Medium.woff') format('woff'),
        url('../fonts/TTInterfaces-Medium.svg#TTInterfaces-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

