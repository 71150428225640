@media (min-width: 1180px){
	.pc-none{
		display: none!important;
	}
}
@media (max-width: 1180px){
	.top-info{
		max-width: 564px;
	}
	.page-wrapper .container{
		max-width: 704px;
	}
	.zones-block{

	}
	.nav{
		max-width: 73px;
	}
	.top-info{
		padding-top: 20px;
		overflow: hidden;
	}
	.top-info-tablet{
		max-width: 434px;
	}
	.top-info-wrapper{
		min-width: 564px;
	}
	
	.arenas .type-block{
		margin-bottom: 0;
	}
	.zones .row{
		display: flex;
		flex-wrap: nowrap;

		
	}
	.zones{
		margin-right: -20px;
	}
	.zones-block{
		min-width: 410px;
		margin-right: 20px;
	}
	.zones-block{
		min-width: 350px;
	}
	.zones-block__text {
	    font-size: 14px;
	    font-weight: 400;
	    line-height: 18px;
	    min-height: 54px;
	    margin-bottom: 9px;
	}

	.single-gallery__item{
		max-width: 16%;
	}
	.price{
		margin-right: -20px;
		.row{
			display: flex;
			flex-wrap: nowrap;
			overflow-x: scroll;

		}
	}
	.price-block{
		min-width: 271px;
		margin-right: 18px;
	}
	.price-title{
		font-size: 16px;
		max-width: 410px;
	}
	.comfort-block{
		max-width: 48%;
	}
	.comfort-block .content{
		padding-left: 0;
	}
	.comfort-block__title{
		font-size: 16px;
		line-height: 130%;
	}
	.comfort-block__subtext{
		font-size: 14px;
		line-height: 18px;
	}
	.comfort-block .list-big li{
		font-size: 14px;
		line-height: 18px;
	}
	.comfort-block ul li::after{
		top: 8px;
	}
	.comfort-block{
		margin-bottom: 30px;
	}
	.food-block__img{
		bottom: 6px;
		right: -186px;
		max-width: 380px;
	}
	.nav{
		padding-left: 20px;
		padding-right: 20px;
	}
	.food{
		overflow: hidden;
	}
	.booking-places{
		display: none;
	}
	.arena-block{
		max-width: 48%;
		width: 100%;
		.arena-block__img{
			width: 100%;
		}
	}
	.arena-block .content{
		padding-left: 20px;
	}
	.main-arenas .main-block__title{
		font-size: 16px;
		line-height: 16px;
	}
	.main-block__more{
		font-size: 12px;
	}
	.soon-block{
		max-width: 32%;
	}
	.application{
		.col{
			max-width: 350px;
		}
	}
	.application__title{
		font-size: 26px;
	}
	.application-btn{
		max-width: 160px;
	}
	.application__subtitle{
		font-size: 18px;
		line-height: 22px;
	}
	.application-list{
		font-size: 14px;
		line-height: 18px;
	}
	.application-phone{
		max-width: 258px;
		right: -35px;
		top: 150px;
	}
	.application .row{
		padding-top: 20px;
	}

	.menu__title{
		display: none;
	}
	.nav-btns{
		height: 0px;
		overflow: hidden;
		opacity: 0;
	}
	.logo-text{
		opacity: 0;
		height: 0px;
	}
	.menu ul li{
		a{
			color: transparent;
		}
	}
	.menu ul li.active a{
		color: transparent;
	}
	.menu ul{
		margin-bottom: 0;
	}
	.logo img {
	    max-width: 38px;
	}
	.nav{
		transition: .4s all ease;
		
	}
	.menu ul li .icon{
		min-width: 32px;
	}
	.page-left{
		max-width: 73px;
	}
	.page-right{
		max-width: 604px;
		min-width: 604px;
	}
	.fresh-block-photo{
		width: 100%;
		height: 188px;
		max-width: 224px;
	}
	.map-wrapper{
		min-height: 235px;
	}
	
	.fresh-block{
		background-image: url('../img/bg-blocks/fresh-block-border-tablet.svg');
	}

	.fresh-block__title{
		font-size: 18px;
	}
	.fresh-block__text{
		margin-bottom: 16px;
	}
	.fresh-block__more{
		font-size: 14px;
	}
	.fresh-block__more::after{
		top: 51%;
	}
	.burger{
		display: flex;
		align-items: center;
		margin-right: 10px;
		svg{
			width: 19.25px;
			height: 22px;
		}
	}
	.info-soc{
		margin-right: 6px;
		margin-left: 10px;
	}
	.top-info{
		flex-wrap: nowrap;
	}
	

	.language-select span{
		display: none;
	}
	.language-select {
	    margin-right: 5px;
	}
	.page-wrapper{
		.container{
			overflow: hidden;
		}
	}
	.page-row{
		flex-wrap: nowrap;
	}
	.logo img{
		transition: .4s all ease;
	}
	.page-left{
		margin-right: 20px;
		transition: .4s all ease;
		overflow: hidden;
		flex-shrink: 0;
	}
	
	.nav-btns{
		transition: .4s all ease;
		
	}

	.nav-reservation{
		display: none;
	}
	.page-row{
		border-radius: 20px;
		overflow: hidden;
	}

	.main-block-slider{
		max-width: 100%;
	}
	.main-wrapper{
		max-width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
	}
	.main-wrapper .main-block{
		max-width: 49%;
		width: 100%;
	}
	.direction{
		margin-right: -20px;
		.row{
			display: flex;
			flex-wrap: nowrap;
			overflow-x: scroll;
			padding-right: 20px;
			&::-webkit-scrollbar {
			  width: 0px;
			}
		}
	}
	.direction-block{
		margin-right: 20px;
		&:last-child{
			margin-right: 0;
		}
	}

	.type-block{
		max-width: 100%;
		order: -1;
		margin-bottom: 30px;
	}
	.type .col{
		max-width: 100%;
	}
	.type-navslider__slide{
		max-width: 123px;
	}

	.type{
		.type-btn{
			width: 49%;
			img{
				width: 100%;
			}
		}
	}
	.type-characteristics .wrapper{
		max-width: 49%;
	}

	.cities-block{
		max-width: 49%;
		margin-bottom: 8px;
	}
	.cities-block__more{
		font-size: 10px;
	}
	.cities-block__more::after{
		width: 20px;
		height: 4px;
	}
	.cities-block__title{
		font-size: 16px;
		margin-bottom: 0;
		line-height: 20px;
	}
	.cities-block--disable .cities-block__opening{
		font-size: 12px;
	}
	.cities-block--disable .cities-block__opening{
		margin-top: 0;
	}
	.cities-block .content{
		padding-bottom: 13px;
	}

	.first-screen__block{
		padding-left: 40px;
		padding-top: 105px;
		min-height: 378px;
	}
	.tidings-block.block-big{
		max-width: 48%;
		min-height: 240.893px;
		padding-top: 0;
		padding-bottom: 0;
	}
	.tidings-block{
		max-width: 48%;
		background-size: cover;
	}
	.community-block{
		max-width: 172px;
		margin-right: 5px;
		margin-left: 5px;
	}
	.footer-list {
	    margin-right: 26px;
	}
	.footer{
		.row{
			flex-wrap: nowrap;
		}
	}
	.footer-soc{
		width: 100%;
		max-width: 100px;
	}
	.footer__mail{
		font-size: 13px;
	}
	.col-logo{
		max-width: 120px;
	}
	.footer-list {
	    margin-right: 46px;
	}
	.footer-soc-icon{
		margin-right: 5px;
		max-width: 15px;
		svg{
			height: auto;
		}
	}
	.footer-soc-icons{
		justify-content: space-between;
	}
	.type-block-small{
		max-width: 291.24px;
	}

	.tidings-block.block-big{
		margin-bottom: 20px;
		padding-left: 12px;
		padding-bottom: 15px;
	}
	.tidings-block{
		margin-bottom: 20px;
		padding-right: 20px;
	}
	.calendar-list{
		max-width: 234px;
	}
	.calendar-item{
		padding-left: 13px;
		padding-right: 13px;
	}
	.calendar-item__name{
		font-size: 12px;
	}
	.calendar{
		margin-bottom: 40px;
	}
	.bridge .bridge-line{
		margin-bottom: 50px;
		flex-direction: column;
		justify-content: unset;
		align-items: flex-start;
	}
	.bridge-item{
		min-width: 402px;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.bridge .bridge-line{
		margin-bottom: 25px;
	}


	.partnership-block{
		max-width: 49%;
	}
	.partnership-block--big{
		max-width: 100%;
	}
	.partnership-block .content{
		padding-left: 20px;
		padding-right: 10px;
	}
	.partnership-block__title{
		font-size: 18px;
		line-height: 22px;
	}
	.partnership-block-list{
		font-size: 18px;
		    line-height: 22px;
	}
	.partnership__title{
		font-size: 35px;
	}
	.birthday{
		br{
			display: none;
		}
	}
	.birthday-block__subtitle{
		max-width: 250px;
	}
	.individual{
		background-size: cover;
	}
	.individual-bottom{
		flex-wrap: wrap;
	}
	.individual-btn{
		margin-top: 20px;
	}
	.partnership__title{
		font-size: 32px;
	}
	.partners{
		margin-right: -20px;
	}
	.partners-row{
		display: flex;
		flex-wrap: nowrap;
		overflow-x: scroll;
	}
	.partners-nav{
		overflow-x: scroll;
	}
	.partners-nav__item{
		margin-right: 30px;
	}
	.partners-block{
		min-width: 270px;
		margin-right: 20px;

	}
	.birthday-block{

	}
	.news-all__title{
		font-size: 14px;
	}
	.news-all__item{
		flex-direction: column;
		align-items: flex-start;
	}
	.news-all{
		padding-left: 0;
	}
	.news-all__item{
		margin-bottom: 15px;
	}
	.news-all__date{
		margin-bottom: 5px;
	}
	.news-single-photo{

	}
	.news-single-content{
		max-width: 100%;
	}
	.news-all-title{
		margin-bottom: 20px;
	}
	
	.team-block{
		max-width: 48%;
	}
	.team-block .content{
		padding-left: 20px;
		padding-bottom: 20px;
	}
	.team-block__title{
		font-size: 36px;
	}
	.team-block__subtext{
	    font-size: 14px;
	    line-height: 18px;
	}

	.contacts-block{
		max-width: 48%;
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0;
		}
		.block-bg{
			width: 100%;
		}
	}

	.about-block{
		max-width: 32%;
	}
	.about-block-photo{
		min-height: 170px;
	}
	.about-block__title{
		font-size: 15px;
	}
	.about-block__subtitle{
		font-size: 10px;
	}
	.biggest-block{
		max-width: 49%;
		background-size: cover;
		&:nth-last-child(2){
			max-width: 100%;
			width: 100%;
		}
		&:nth-last-child(1){
			max-width: 100%;
			width: 100%;
		}
	}
	.biggest{
		.partnership__title{
			font-size: 29px;
		}
	}
	.biggest-wrapper{
		padding-top: 35px;
		padding-bottom: 35px;
	}
	.massmedia-block{
		margin-bottom: 20px;

	}
	.massmedia-row{
		margin-bottom: -20px;
	}
	.partners-about-slide{
		max-width: 130px;
		height: 70px;
		margin-right: 3px;
		margin-left: 3px;
		img{
			max-width: 68%;
		}
	}
	.height-block-small{
		max-width: 49%;
	}
	.height-block__text{
		br{
			display: none;
		}
	}
	.height-block .content{
		margin-top: 10px;
		padding-left: 0px;
	}
	.height-block__text{
		font-size: 14px;
		line-height: 20px;
	}
	.height-block__title{
		font-size: 16px;
	}
	.height{
		margin-bottom: 60px;
	}
	.locations{
		.type-block{
			position: unset;
			max-width: 100%;
			flex-direction: row;
			display: flex;
			justify-content: space-between;
		}
		.type-block-col{
			max-width: 48%;
			width: 100%;
		}
		.type-block-small .type-navslider{
			padding-bottom: 0;
			margin-bottom: 0;
			border: none;
		}
	}

	.locations-span{
		display: none;
	}
	.locations__title{
		font-size: 22px;
		max-width: 300px;
	}
	.locations__subtitle{
		font-size: 12px;
	}
	.locations-info{
		width: 295px;
		height: 88.81px;
	}
	
	.offers-img{
		bottom: 6px;
		    left: 339px;
		    max-width: 230px;
	}
	.offers-about .content{
		padding-left: 60px;
		padding-top: 69px;
	}
	.massmedia-block{
		max-width: 240px;
		min-width: 240px;
		margin-right: 30px;
		&:last-child{
			margin-right: 0;
		}
	}
	.massmedia-block-photo{
		min-height: 215px;
	}
	.massmedia-block .content{
		padding-left: 0;
	}
	.massmedia-row{
		flex-wrap: nowrap;
		overflow-x: scroll;
	}

	.contacts-reservation-block{
		.block-bg{
			
		}
		.nav-reservation{
			display: flex;
		}
		.content{
			padding-left: 25px;
			padding-top: 45px;
		}
	}
	.contacts-reservation-line{
		flex-wrap: wrap;
	}
	.contacts-reservation__title{
		font-size: 30px;
	}
	.contacts-reservation-line{
		margin-top: 30px;
	}
	.contacts-reservation-item{
		margin-bottom: 20px;
	}
	.contacts-reservation-line{
		margin-bottom: -20px;
	}

	.offers-partnership .content{
		padding-left: 44px;
		padding-top: 62px;
		padding-bottom: 39px;
	}

	.single-block{
		.single-block-img{
			
		}
	}
	.single-block__title{
		font-size: 50px;
		line-height: 100%;
	}
	.single-block .type-characteristics-items{
		margin-bottom: 10px;
	}
	.single-play{
		position: absolute;
		top: 79px;
		right: 39px;
		width: 80px;
		height: 80px;
		svg{
			max-width: 15px;
			height: auto;
		}
	}
	.single-google{
		align-self: flex-end;
		top: 11px;
		position: relative;
	}
	.single-block .line{
		flex-direction: column;
		align-items: flex-start;
	}
	.games-block .content{
		padding-left: 20px;
		padding-right: 20px;
	}
	.games-block__logo{
		display: none;
	}
	.games-slide-item{
		width: 33%;
	}


	.food .content{
		padding-left: 50px;
	}

	.open-select-wrapper{
		
	}
	.open-select-item{
		font-size: 9px;
		padding-right: 8px;
		padding-left: 8px;
		padding-top: 0;
		line-height: 18px;
		padding-bottom: 0;
		border-radius: 5px;
	}
	.open-select-wrapper{
		padding-top: 5px;
	}
	.open-select-item::after{
		width: 6px;
		height: 6px;
		top: -2px;
	}
	.open-select-wrapper{
		right: 7px;
	}

	.page-left.menu-active{
		.nav{
			max-width: 230px;
		}
	}

	.page-left.menu-active{
		max-width: 230px;
		//min-width: 230px;
		.logo-text{
			opacity: 1;
			height: unset;
			white-space: nowrap;
		}
		.logo img{
			max-width: 150px;
		}
		.menu ul li a{
			color: #797979;
		}
		 .menu ul li a{

		}
		.nav-btns{
			padding-top: 30px;
			opacity: 1;
			height: auto;
		}
		.nav-reservation{
			display: flex;
			white-space: nowrap;
		}
	}
}
@media (min-width: 767px){
	.mob-true{
		display: none!important;
	}
}


@media (max-width: 767px){
	.page-left{
		.nav{
			max-width: 230px;
		}
	}
	.page-row .page-left{
		max-width: 230px;
		//min-width: 230px;
		.logo-text{
			opacity: 1;
			height: unset;
			white-space: nowrap;
		}
		.logo img{
			max-width: 150px;
		}
		.menu ul li a{
			color: #797979;
		}
		 .menu ul li a{

		}
		.nav-btns{
			padding-top: 30px;
			opacity: 1;
			height: auto;
		}
		.nav-reservation{
			display: flex;
			white-space: nowrap;
		}
	}
	.pc-true{
		display: none!important;
	}
	.tidings-mobile-btn{
		justify-content: center;
		display: flex;
		padding-right: 20px;
	}
	.community-block{
		max-width: 100%;
		margin-bottom: 10px;
		margin-right: 0;
		margin-left: 0;
		padding-top: 19px;
		padding-bottom: 15px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.footer .row{
		flex-wrap: wrap;
	}
	.footer-info{
		flex-wrap: wrap;
	}
	.footer-list{
		margin-right: 0;
		width: 100%;
	}
	.footer-soc{
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 100%;
	}
	.community{
		padding-top: 30px;
		padding-bottom: 0px;
		margin-bottom: 50px;
	}
	.tidings__title{
		font-size: 25px;
	}
	.community__title{
		font-size: 25px;
	}
	.tidings-category{
		min-width: max-content;
	}
	.tidings-categories{
		flex-wrap: wrap;
	}
	.tidings-categories{
		.wrapper{
			display: flex;
			overflow-x: scroll;
			
		}
	}
	.tidings{
		margin-right: -20px;
	}
	.tidings-row{
		padding-right: 20px;
	}
	.single-block .content{
		.btn-figure-bg, .btn-figure{
			width: 100%;
		}
	}
	
	.single-block .content{
		padding-left: 20px;
		padding-right: 20px;
	}
	.single-block__title{
		font-size: 25px;
	}
	.single-google{
		position: static;
		align-self: flex-start;
	}
	.single-block .line{
		margin-bottom: 20px;
	}
	.tidings-block.block-big{
		max-width: 100%;
		margin-bottom: 20px;
	}
	.tidings-block{
		max-width: 100%;
		margin-bottom: 20px;
	}
	.first-screen__block{
		padding-left: 15px;
		padding-top: 30px;
	}
	.first-screen-btn{
		width: 165px;
		text-align: center;
		justify-content: center;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.first-screen__block__text{
		font-size: 12px;
	}
	.first-screen__block__title{
		font-size: 20px;
		margin-bottom: 15px;
	}
	.first-screen-btns {
	    margin-top: 15px;
	}
	.first-screen__block{
		min-height: 1px;
		padding-bottom: 45px;
	}
	.zones{
		
	}
	.zones-block {
	    min-width: 301px;
	    margin-right: 0;
	    margin-bottom: 20px;
	    margin-right: 20px;
	    .btn-figure{
	    	max-width: 190px;
	    	span{
	    		font-size: 14px;
	    		line-height: 14px;
	    	}
	    }
	    .btn-big{
	    	max-width: 250px;
	    }
	}
	.zones-block__img {
	    margin-bottom: 5px;
	}
	.zones-block__text{
		font-size: 12px;
		line-height: 16px;
		min-height: 48px;
	}
	.price-title{
		max-width: 100%;
		font-size: 13px;
		line-height: 17px;
	}
	.night{
		.block-bg{
			img{
				
			}
		}
	}
	.night-block .content{
		padding-left: 20px;
	}
	.night-block__title{
		font-size: 65px;
	}
	.night-block__subtext{
		font-size: 16px;
		padding-right: 20px;
		padding-left: 13px;
	}
	.night-block__subtext{
		margin-bottom: 30px;
	}
	.night-btn{
		align-self: flex-start;
	}
	.comfort-block{
		max-width: 100%;
	}
	.single-gallery__item{
		max-width: 49%;
		width: 100%;
		margin-bottom: 6px;
		img{
			width: 100%;
		}
	}
	.zones-block__title{
		font-size: 17px;
	}
	.soon-block{
		max-width: 49%;
		margin-bottom: 8px;
	}
	.soon-block-bottom{
		img{
			max-width: 80px;
		}
	}
	.arena-block{
		max-width: 100%;
	}
	.section-title--big{
		font-size: 15px;
	}
	.main-arenas .section-title{
		top: 0px;
	}
	.main-arenas {
	    padding-top: 10px;
	}
	section {
	    margin-bottom: 40px;
	}

	.nav{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.page-left{
		max-width: 52px;
	}
	.top-info{
		.search{
			display: none;
		}
	}
	body{
		
	}

	.page-wrapper{
		padding-right: 10px;
		padding-left: 10px;
		.section-bg{
			display: none;
		}
	}
	.page-left{
		margin-right: 10px;
		position: relative;

	}
	.nav{
		border-radius: 10px;
	}
	.select-country{
		height: auto;
		border: none;
		border-radius: 0px;
		padding: 0px;
		img{
			display: none;

		}
	}
	.page-right{
		min-width: calc(100% - 62px);
		max-width: unset;
	}
	.select-country{
		strong{
			display: none;
		}
	}
	.page-row{
		border-radius: 10px;
	}
	.map-wrapper{
		background: grey;
		background-image: none!important;
		min-height: 175px;
		border-radius: 5px;
	}
	.main-top{
		align-items: center;
		img{
			max-width: 100px;
		}
	}
	.fresh-block{
		flex-direction: column;
		background-image: none!important;
		border: 1px solid #313131;
		border-radius: 10px;
		height: auto;
		padding: 20px;
	}
	.fresh-block__time{
		position: static;
		transform: unset;
		align-self: flex-start;
		margin-top: 5px; 
	}
	.fresh-block-photo{
		margin-right: 0;
		width: 100%;
		max-width: 100%;
		min-height: 1px;
		margin-bottom: 20px;
	}
	.main-top--standart .section-title{
		top: 0px;
	}
	.news-link{
		margin-right: 0;
	}
	.fresh-block__title{
		br{
			display: none;
		}
	}
	.info-soc{
		margin-right: 0;
		margin-left: 0;
	}
	.language-select{
		margin-right: 15px;
	}

	.mob-none{
		display: none!important;
	}

	.page-right{
		border-radius: 10px;
	}
	.page-top{
		border-radius: 0px 0px 10px 10px;
	}

	.main-wrapper .main-block{
		max-width: 100%;
	}
	.main-wrapper{
		flex-wrap: wrap;
	}
	.main-block--big{
		padding-left: 20px;
		min-height: 260px;
	}
	.main-wrapper .main-block__title{
		max-width: 250px;
	}
	.main-block--big .main-block__text{
		font-size: 12px;
		line-height: 16px;
		max-width: 230px;
	}
	.main-block--big .main-block__title{
		font-size: 20px;
		line-height: 110%;
	}
	.direction-block{
		margin-right: 10px;
	}
	.type-block{
		padding: 20px;
	}
	.type-navslider__slide{
		max-width: 49%;
		margin-bottom: 5px;
	}
	.type-block-video{
		max-width: 100%;
		margin-top: 30px;
	}
	.type-block__title{
		margin-bottom: 30px;
	}
	.type-characteristics .wrapper{
		max-width: 100%;
	}
	.type-power{
		margin-top: 30px;
	}
	.type .type-btn{
		width: 100%;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
	}


	.cities-block{
		max-width: 100%;
	}

	.tournaments-arrows{
		display: none;
	}
	.calendar-list{
		max-width: 100%;
		margin-top: 20px;
	}
	.calendar-block-slider{
		max-width: 100%;
		order: -1;
	}
	.calendar-block .calendar-block-photo{
		min-height: 250px;
	}
	.calendar-block .type-navslider__slide{
		max-width: 57px;
		min-height: 57px;
	}
	.calendar-block .type-block{
		margin-bottom: 0;
	}
	.calendar-block .type-block-video{
		max-width: 109px;
		min-height: 96.474px;
		margin-top: 0;
	}
	.tidings__all{
		margin-top: 10px;
	}
	.cybersport__title{
		font-size: 25px;
	}
	.bridge{
		padding-right: 20px;
	}
	.bridge__title{
		font-size: 25px;
		br{
			display: none;
		}
	}
	.calendar-block .type-navslider__slide{
		max-width: 23.7%;
		width: 100%;
	}
	.calendar-block .type-block-video{
		max-width: 49%;
	}
	.type-block-small .wrapper{
		max-width: 49%;
	}
	.calendar-block .type-block__title{
		font-size: 13.5px;
	}
	.bridge-list{
		font-size: 16px;
		line-height: 18px;
	}
	.bridge-item{
		min-width: 1px;
		max-width: 100%;
	}
	.bridge{
		padding-left: 15px;
		padding-right: 15px;
	}
	.bridge-item__title{
		font-size: 18px;
	}
	.bridge-item__text{
		font-size: 16px;
		line-height: 20px;
	}
	.bridge-item{
		padding-right: 10px;
		width: 100%;
	}
	.bridge-item .icon{
		max-width: 50px;
		height: 50px;
		img{
			max-width: 28px;
		}
	}
	.bridge-item .icon{
		margin-right: 10px;
	}
	.bridge-item__title{
		font-size: 16px;
	}
	.bridge-item__text{
		font-size: 13px;
	}
	.bridge-list li::after{
		width: 10px;
		height: 10px;
		top: 6px;
	}
	.bridge-list li{
		padding-left: 23px;
	}
	.cybersport__text{
		font-size: 14px;
		line-height: 17px;
	}
	.community{
		padding-bottom: 30px;
	}
	.community-row{
		padding-right: 20px;
		padding-left: 20px;
	}
	.community-block__title{
		font-size: 22px;
		margin-top: 14px;
		margin-bottom: 14px;
	}
	.footer-list{
		max-width: 49%;
	}
	.footer-soc-item{
		margin-bottom: 0;
		margin-right: 30px;
		&:last-child{
			margin-right: 0;
		}
	}
	.footer-list ul{
		font-size: 10px;
		margin-bottom: 8.5px;
	}
	.footer-list__title{
		font-size: 15px;
	}
	.footer-soc{
		margin-top: 30px;
	}
	.footer__logo{
		max-width: 145px;
	}
	.footer__mail{
		margin-top: 20px;
	}
	.footer-info{
		margin-top: 20px;
	}
	.footer{
		padding-bottom: 20px;
	}
	.footer-soc{
		justify-content: flex-start;
	}

	.news-single-text{
		padding-left: 0;
		font-size: 14px;
		br{
			display: none;
		}
	}
	.page-bread--big{
		font-size: 13px;
	}
	.news-single-video{
		min-height: 160px;
	}
	.news-single-video__play{
		max-width: 35px;
	}
	.news-single__title{
		font-size: 18px;
	}
	.news-single-photo{
		padding-left: 20px;
	}
	.news-single__date{
		opacity: 1;
	}
	.news-single-photo{
		min-height: 200px;
	}

	.team-block{
		max-width: 100%;
	}
	.team__title{
		font-size: 25px;
		    line-height: 114.5%;
	}

	.application-phone{
		position: unset;
		transform: none;
		margin-top: 20px;
		max-width: 100%;
	}
	.application .row{
		position: unset;
	}
	.application-btn{
		margin-right: 0;
		max-width: 48%;
	}
	.application-btns{
		justify-content: space-between;
		display: flex;
		margin-top: 20px;
	}
	.application__title{
		font-size: 20px;
	}
	.application__subtitle{
		font-size: 18px;
		line-height: 20px;
	}
	.application .main-top img{
		display: none;
	}
	.application .row{
		padding-top: 0;
	}
	.application{
		
	}

	.about-block{
		max-width: 100%;
		margin-bottom: 20px;
	}
	.about-block-photo{
		min-height: 210px;
	}
	.about{
		.partnership__title{
			font-size: 25px;
		}
	}
	.biggest-wrapper{
		border-radius: 25px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.biggest-block{
		max-width: 100%;
	}
	.about-block{
		&:last-child{
			margin-bottom: 0;
		}
	}
	.biggest .partnership__title{
		font-size: 22px;
	}
	.biggest-wrapper{
		border-radius: 10px;
	}
	.biggest-block:nth-last-child(1){
		min-height: 230px;
	}
	.height-block-small{
		max-width: 100%;
	}
	.contacts-block{
		max-width: 100%;
		margin-bottom: 10px;
	}
	.contacts-block__title{
		min-height: 1px;
	}
	.partnership__title{
		font-size: 25px;
		letter-spacing: normal;
	}
	.height{
		margin-bottom: 40px;
	}
	.contacts-block .content{
		padding-left: 22px;
	}
	.massmedia-block{
		min-width: 250px;
		max-width: 250px;
	}
	.partners-about-slide{
		max-width: 80px;
		height: 50px;
	}
	.arenas .type-block, .locations .type-block{
		min-width: 220px;
		padding: 15px 10px;
		.type-navslider__slide{
			max-width: 48%;
			min-height: 81.682px;
		}
		.type-block-video{
			width: 100%;
			max-width: 100%;
			min-height: 121px;
			margin-top: 10px;
		}
		.type-power{
			margin-top: 15px;
		}
		.type-btn{

		}
		.type-block__title{
			font-size: 25px;
			line-height: 26px;
		}
		.type-block__time{
			max-width: 100%;
		}
		.type-block__where{
			font-size: 14px;
			line-height: 17px;
		}
		.type-characteristics__title{
			font-size: 15px;
			max-width: 90%;
		}
		.type-btn{
			max-width: 100%;
			
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: 0;
			}
			span{
				font-size: 12px;
			}
		}
		.list-circle{
			font-size: 12px;
			line-height: 16px;
		}
		.list-circle li::before{
			top: 7px;
		}
		.wrapper{
			max-width: 100%;
		}
		.type-btns{
			justify-content: center;
		}

		.type-characteristics__item span{
			font-size: 12.015px;
			line-height: 8.671px;
		}
		.type-characteristics__item strong{
			font-size: 7.01px;
			padding: 3px;
			letter-spacing: -0.14px;
		}
		.type-characteristics__item{
			border-radius: 2.575px;
		    padding-left: 4px;
		    padding-top: 4px;
		    padding-bottom: 4px;
		    padding-right: 5px;
		    margin-bottom: 7px;
		    margin-right: 5px;
		}
	}
	

	.arenas .type-block{
		margin-right: 15px;
		&:last-child{
			margin-right: 0;
		}
	}
	.arenas-row{
		padding-bottom: 0;
	}
	.height{
		.partnership__title{
			font-size: 16px;
		}
	}
	.massmedia-block-photo {
	    min-height: 199px;

	}
	.massmedia-block{
		min-width: 220px;
		margin-right: 15px;
	}
	.contacts-block .content{
		padding-top: 17px;
	}
	.offers__title{
		font-size: 20px;
		margin-bottom: 5px;
		br{
			display: none;
		}
	}
	.offers-about .offers__title{
		margin-bottom: 5px;
	}
	.offers-about .content{
		padding-left: 20px;
		padding-top: 35px;
	}
	.offers__subtitle{
		font-size: 12px;
	}
	.offers-btn{
		max-width: 150px;
		margin-bottom: 10px;
		span{
			font-size: 12px;
		}
	}
	.offers-about{
		
	}
	.contacts-block{
		width: 100%;
	}
	.contacts-block .content{
		padding-bottom: 17px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
	.contacts-block__title{
		max-width: 90%;
	}
	.locations-info{
		position: static;
		width: auto;
		height: auto;
	}
	.massmedia-row{
		margin-bottom: 0;
	}
	.massmedia-block{
		margin-bottom: 0;
	}
	.offers-about{
		margin-bottom: 40px;
	}
	.locations-info{
		margin-bottom: 15px;
	}

	.locations .type-block-col{
		max-width: 100%;
	}
	.locations .type-block{
		flex-direction: column;
	}
	.locations .type-block{
		margin-bottom: 0;
	}
	.offers-about .content{
		padding-top: 48px;
	}
	.offers-img{
		right: 27px;
		left: auto;
		bottom: 55px;
		max-width: 130px;
	}
	.offers-contacts{
		.content{
			padding-left: 20px;
			padding-top: 48px;
		}
	}

	.contacts-reservation__title{
		font-size: 25px;
	}
	.contacts-reservation-block .content{
		padding-left: 30px;
		padding-right: 10px;
	}
	.contacts-reservation-block .block-bg img {
	    
	}
	.contacts-reservation-block .content {
	    padding-top: 56px;
	}
	.contacts-reservation-item{
		margin-right: 0;
	}
	.offers__title{
		max-width: 233px;
	}

	.partnership-block{
		max-width: 100%;
	}

	.birthday-block--big .line{
		flex-direction: column;
		align-items: flex-start;
	}
	.birthday-btn{
		margin-top: 20px;
	}
	.birthday-block__title{
		font-size: 18px;
		line-height: 18px;
		letter-spacing: -0.42px;
		max-width: 164px;
	}
	.birthday-block--right .content{
		align-items: flex-start;
	}
	.birthday-block .content{
		padding-bottom: 25px;
	}
	.birthday-block{
		.block-bg{
			width: 100%;
		}
		img{
			width: 100%;
		}
	}
	.birthday-block{
		width: 100%;
	}
	.partners-nav__item{
		max-width: 100px;
		max-height: 20px;
		margin-right: 15px;
	}
	.offers-partnership .content{
		padding-left: 20px;
		padding-top: 48px;
	}
	.partners-block{
		margin-right: 10px;
		min-width: 245px;
		.content{
			padding-top: 38px;
		}
	}
	.partners-slide{
		min-height: 139px;
	}
	.partners-navslider__slide{
		width: 32%;
	}
	.individual{
		padding-left: 15px;
		padding-right: 15px;
		.partnership__title{
			font-size: 20px;
			margin-bottom: 10px;
		}
	}
	.individual__subtitle{
		font-size: 14px;
	}
	.individual-list{
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 20px;
	}
	.individual-block__title{
		font-size: 16px;
	}
	.individual-block{
		padding: 20px 15px;
	}
	.individual-block__text{
		font-size: 14px;
		line-height: 18px;
	}

	.partnership-block--big{
		width: 100%;

		.content{
			padding-bottom: 60px;
			padding-top: 60px;
			justify-content: center;
		}
		.block-bg{
			width: 100%;
			img{
				width: 100%;
			}
		}
	}


	.price-block{
		margin-right: 15px;
	}

	.food{
		.block-bg{
			img{
				
			}
		}
	}
	.food .block-bg img{
		
	}
	.food-block__img{
		display: none;
	}
	.food .content{
		padding-left: 20px;
	}
	.food-item p{
		max-width: unset;
	}
	.food-item .icon{
		flex-shrink: 0;
	}
	.food-item{
		margin-bottom: 25px;
	}


	.games-slide-item{
		width: 50%;
	}
	.games{
		.block-bg{
			display: none;
		}
	}
	.games-block .content{
		position: relative;
		padding-left: 10px;
		padding-right: 10px;
		border: 1px solid #292929;
		border-radius: 20px;
	}
	.games-block-nav__btn{
		font-size: 12px;
	}
	.games-slide-item .photo-bg{
		width: 45px;
		height: 45px;
		flex-shrink: 0;
		margin-right: 5px;
	}
	
	.games-slide-item .text {
	    font-size: 13px;
	    line-height: 18px;
	}
	.games-slider{
		margin-top: 25px;
	}
	.games{
		.section-title{
			font-size: 15px;
		}
	}
	.single-btn span{
		font-size: 16px;
	}
	.single-block__title{
		font-size: 35px;
		margin-bottom: 20px;
	}
	.single-play{
		top: 238px;
		right: 40px;
		width: 80px;
		height: 80px;
	}

	.first-screen__block{
		position: relative;
	}

	.community-row{
		flex-wrap: nowrap;
		overflow-x: scroll;
		justify-content: flex-start;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 10px;
		padding-right: 20px;
		&::-webkit-scrollbar {
		  height: 6px;
		  cursor: pointer;
		}

		&::-webkit-scrollbar-track {
		  border-radius: 10px;
		  background: rgba(255, 255, 255, 0.04);
		  margin-right: 20px;
		}

		&::-webkit-scrollbar-thumb {
		  border-radius: 10px;

		  background: #6FFF8B;
		}
	}
	.community{
		padding-left: 20px;

	}
	.community-block{
		margin-right: 15px;
		min-width: 150px;
		&:last-child{
			margin-right: 0;
		}
	}
	.tidings-row{
		flex-wrap: nowrap;
		justify-content: flex-start;
		overflow-x: scroll;

		&::-webkit-scrollbar {
		  height: 6px;
		  cursor: pointer;
		}

		&::-webkit-scrollbar-track {
		  border-radius: 10px;
		  background: rgba(255, 255, 255, 0.04);
		  margin-right: 20px;
		}

		&::-webkit-scrollbar-thumb {
		  border-radius: 10px;

		  background: #6FFF8B;
		}
	}
	.tidings-block{
		min-width: 240px;
		margin-right: 15px;
		&:last-child{
			margin-right: 0;
		}
	}
	.first-screen__block{
		position: relative;
		overflow: hidden;
		.content{
			position: relative;
			z-index: 2;
		}
		&::after{
			position: absolute;
			border-radius: 23px;
			left: 0px;
			bottom: 0px;
			right: 0;
			top: 0;
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: linear-gradient(266deg, #000 15%, rgba(0, 0, 0, 0.00) 85%);
			transform: scale(-1, 1);
		}
	}

	.biggest-block{
		min-width: 233px;
		margin-right: 15px;
		&:last-child{
			margin-right: 0;
		}
	}
	.biggest-wrapper .row{
		justify-content: flex-start;
		overflow-x: scroll;
		flex-wrap: nowrap;
		align-items: stretch;

		&::-webkit-scrollbar {
		  height: 6px;
		  cursor: pointer;
		}

		&::-webkit-scrollbar-track {
		  border-radius: 10px;
		  background: rgba(255, 255, 255, 0.04);
		  margin-right: 7px;
		}

		&::-webkit-scrollbar-thumb {
		  border-radius: 10px;

		  background: #6FFF8B;
		}
	}
	.biggest-wrapper{
		
	}

	.price-row, .zones-row, .partners-nav, .partners-row, .about-row, .massmedia-row, .fresh-row{
		padding-bottom: 15px;
		justify-content: flex-start;
		overflow-y: hidden;
		&::-webkit-scrollbar {
		  height: 6px;
		  cursor: pointer;
		}

		&::-webkit-scrollbar-track {
		  border-radius: 10px;
		  background: rgba(255, 255, 255, 0.04);
		  margin-right: 20px;
		}

		&::-webkit-scrollbar-thumb {
		  border-radius: 10px;

		  background: #6FFF8B;
		}
	}
	.zones-row{
		padding-bottom: 0;
	}
	.about-row{
		flex-wrap: nowrap;
		justify-content: flex-start;
		overflow-x: scroll;
	}
	.about-block{
		min-width: 200px;
		margin-right: 15px;
		&:last-child{
			margin-right: 0;
		}
	}
	.about-block-photo{
		min-height: 180px;
	}
	.about-row{
		padding-right: 20px;
	}
	.about{
		margin-right: -20px;
	}
	.massmedia{
		margin-right: -20px;
	}
	.massmedia-row{
		padding-right: 20px;
	}
	.arenas-row{
		padding-right: 20px;
		padding-bottom: 20px;
	}
	.fresh-row{
		flex-wrap: nowrap;
		padding-right: 20px;
		overflow-x: scroll;
	}
	.fresh-block{
		min-width: 253px;
		margin-right: 15px;
		&:last-child{
			margin-right: 0;
		}
	}
	.fresh{
		margin-right: -20px;
	}
	.calendar-block-wrapper{
		overflow: hidden;
		max-height: 0px;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
		.calendar-block{
			margin-bottom: 10px;
		}
	}

	.calendar-item.active + .calendar-block-wrapper{
		max-height: 1000px;
		transition: max-height 1s ease-in-out;
	}

	.nav{
		max-width: 52px;
	}
	.top-info-wrapper{
		min-width: 1px;
	}
	.top-info{
		max-width: calc(100% - 102px);
		padding-right: 20px;
		padding-bottom: 0;
		border-bottom: none;
	}
	.top-info-wrapper{
		padding-bottom: 16px;
		border-bottom: 1px solid #333333;
	}

	.locations-blocks{
		display: none;
	}

	.page-left{
		.nav{
			position: static;
		}
	}
	.top-info{
		max-width: calc(100% - 40px);
	}
	.massmedia{
		.partnership__title{
			max-width: 300px;
		}
	}
	.contacts-block__title{
		font-size: 26px;
	}
	.contacts-block__mail{
		font-size: 20px;
	}
	.contacts-block__phone{
		font-size: 26px;
	}
	.contacts-block__mail{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.offers{
		.block-bg{
			width: 100%;
		}
		img{
			width: 100%;
		}
	}
	.offers__title{
		font-size: 30px;
		max-width: 293px;
	}
	.offers__subtitle{
		font-size: 16px;
	}
	.offers-img{
		bottom: 66px;
	}
	.offers-about .content{
		padding-left: 30px;
		padding-top: 90px;
	}

	.offers-contacts .content{
		padding-top: 120px;
		padding-left: 30px;
		.offers__title{
			margin-bottom: 30px;
		}
	}

	.contacts-reservation{
		.block-bg{
			img{
				width: 100%;
			}
		}
	}
	.contacts-reservation__title{
		font-size: 28px;
	}
	.contacts-reservation-item{
		margin-bottom: 35px;
	}
	.contacts-reservation-block .content{
		padding-top: 80px;
	}

	.offers-partnership .content{
		padding-left: 30px;
		padding-top: 100px;
	}
	.single-block-img{
		img{
			width: 100%;
		}
	}
	.food{
		.block-bg{
			img{
				width: 100%;
			}
		}
	}
	.food__title{
		font-size: 22px;
		max-width: 388px;
	}
	.food-item {
	    margin-bottom: 35px;
	}
	.food-block{
		
		.block-bg{
			width: 100%;
		}
		.content{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}
	}
	.food .content{
		padding-bottom: 50px;
	}
	.night{
		.block-bg{
			width: 100%;
			img{
				width: 100%;
			}
		}
	}
	.night-block .content{
		padding-left: 30px;
		padding-top: 60px;
	}
	.night-block__title{
		font-size: 83px;
	}
	.single-block__title{
		font-size: 40px;
	}
	.single-block .content{
		padding-top: 90px;
		padding-bottom: 20px;

	}
	.single-play{
		top: 314px;
	}
	.page-left{
		position: fixed;
		background: #121212;
		z-index: 100000000;
		min-height: 100%;
		left: 0px;
		top: 0px;
		padding-top: 10px;
		border-right: 1px solid #797979;
		transition: .4s all ease;
		transform: translateX(-100%);
	}
	.page-left.menu-active{
		transform: translateX(0);
	}
	.page-left__close{
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.top-info-logo{
		max-width: 60px;
		display: flex;
	}
	.modal-reservation{
		padding-right: 30px;
		padding-left: 30px;
	}
	.modal-reservation .modal-window__inner{
		width: 100%;
		.block-bg{
			img{
				width: 100%;
			}
		}
	}
	.modal-reservation .modal-window__close{
		right: 0px;
	}
}

@media (max-width: 400px){
	.contacts-block__title{
		font-size: 22px;
	}
	.contacts-block__mail{
		margin-top: 0;
		margin-bottom: 0;
	}
	.offers__title{
		font-size: 26px;
	}
	.offers-about .content{
		padding-top: 70px;
	}
	.offers-img{
		bottom: 58px;
	}
	.offers__subtitle{
		font-size: 14px;
	}
	.modal-reservation{
		padding-right: 50px;
		padding-left: 50px;
		.contacts-reservation-block .content{
			padding-top: 71px;
		}
	}
	.modal-window__inner .type-btn span{
		font-size: 15px;
	}
	.single-block__title{
		font-size: 25px;
	}
	.single-btn span{
		font-size: 14px;
	}
	.single-google{
		width: 100px;
		height: 134.61px;
		svg{
			height: auto;
			max-width: 69%;
		}
	}
	.single-play{
		top: 235px;
		right: 20px;
	}
	.single-google__count{
		font-size: 35.985px;
		line-height: 34px;
	}
	.single-google__text{
		font-size: 12px;
		margin-bottom: 2px;
	}
	.games-slide-item{
		width: 48%;
	}
	.games-slide-item .text{
		font-size: 10px;
		line-height: 14px;
	}
	.games-slide-item .photo-bg{
		width: 35px;
		height: 35px;
		flex-shrink: 0;
		margin-right: 5px;
	}
	.news-link{
		display: none;
	}
	.night-block__title{
		font-size: 50px;
	}
	.night-block__subtext{
		width: 202px;
		    height: 53px;
	}
	.food__title{
		font-size: 16px;
	}
	.food-item p{
		font-size: 12px;
		line-height: 16px;
	}
	.food-item{
		margin-bottom: 15px;
	}
	.food-list{
		margin-bottom: 25px;
	}
	.food-btn{
		max-width: 200px;
		span{
			font-size: 12px;
		}
	}

	.info-soc__link{
		max-width: 25px;
	}
	.fresh-block-photo{
		height: 150px;
	}
	
	.main-top img {
	    max-width: 125px;
	}
	.section-title{
		font-size: 18px;
	}
	.cities-block .content{
		padding-left: 15px;
	}
	.main-block{
		padding-right: 15px;
	}
	.main-wrapper .main-block__title{
		font-size: 17px;
	}
	.main-wrapper .main-block__text{
		br{
			display: none;
		}
	}
	.main-top img{
		display: none;
	}
	.soon-block-bottom img{
		max-width: 70px;
	}
	.soon-block__count{
		font-size: 16px;
	}
	.soon-block__title{
		font-size: 12px;
		line-height: 14px;
	}

	.contacts-reservation__title{
		font-size: 18px;
	}
	.contacts-reservation-block .content{
		padding-left: 15px;
		padding-top: 47px;
	}
	.contacts-reservation-item__title{
		margin-bottom: 8px;
	}
	.contacts-reservation-line{
		margin-top: 15px;
	}
	.contacts-reservation-item{
		margin-bottom: 15px;
	}

	.contacts-reservation__title{
		font-size: 28px;
	}
	.contacts-reservation-item {
	    margin-bottom: 25px;
	}
	.contacts-reservation-block .content{
		padding-top: 62px;
	}

	.food-item p {
	    font-size: 14px;
	    line-height: 18px;
	}
	.food__title {
	    font-size: 26px;
	    line-height: 30px;
	}
	.food-btn {
	    max-width: 240px;
	    span{
	    	font-size: 14px;
	    }
	}
	.night-block__title{
		font-size: 65px;
	}

	.single-play {
	    top: 284px;
	    right: 50px;
	}
	.single-block__title{
		font-size: 35px;
	}
	.modal-reservation{
		padding-right: 30px;
		padding-left: 30px;
	}
}